/**
 * Gets the width of browser window
 * @returns {number}
 */
const screenSize = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

const formatDate = dateString => {
  const dateObj = new Date(dateString).toLocaleDateString();
  return dateObj;
};

const isEmptyObject = obj => Object.entries(obj).length === 0;

const addressFormatter = address => {
  const allowedKeys = [
    'line_1',
    'line_2',
    'line_3',
    'line_4',
    'line_5',
    'postcode',
    'address_line_1',
    'address_line_2',
    'address_line_3',
    'address_line_4',
    'address_line_5',
    'address_postcode',
    'delivery_address1',
    'delivery_address2',
    'delivery_address3',
    'delivery_address4',
    'delivery_address5',
    'delivery_postcode',
  ];

  const filteredAddress = Object.keys(address)
    .filter(key => allowedKeys.includes(key))
    .reduce((obj, key) => {
      obj[key] = address[key];
      return obj;
    }, {});

  const values = Object.values(filteredAddress);

  const nonEmptyValues = values.filter(Boolean);

  const formattedAddress = nonEmptyValues.join(', ');

  return formattedAddress;
};

const safeDecode = input => {
  const decodedString = decodeURIComponent(input);

  // Reduce features string down to 3 items to reduce filter failures
  const reducedString =
    decodedString.split('<br />').slice(0, 3).join('<br />') || '';

  // Replace all <br /> tags with newlines
  const formattedString = reducedString.replace(/<br\s*\/>/gi, '\n');

  // Allow only alphanumeric characters, spaces, commas, periods, and line breaks
  if (/^[a-zA-Z0-9,. °º()&+*\n\r\/:;-]+$/.test(formattedString)) {
    return formattedString;
  } else {
    // Handle unexpected format: either sanitize or throw an error
    return 'Invalid content';
  }
};

export { screenSize, formatDate, isEmptyObject, addressFormatter, safeDecode };
