import React from 'react';
import { SplideSlide } from '@splidejs/react-splide';
import ResponsiveImage from 'components/shared/ResponsiveImage';

const MainSliderBanner = ({ id, url, mobileImage, desktopImage, name }) => {
  const srcSet = [
    {
      id,
      src: desktopImage,
      type: 'image/jpeg',
      media: '(min-width: 768px)',
    },
  ];
  return (
    <SplideSlide
      className={`splide__slide splide__slide--${id} br-8`}
      key={id}
      data-testid="splide__slide--item">
      <a href={url} target="_blank" rel="noreferrer" className="d-block">
        <ResponsiveImage
          srcSet={srcSet}
          fallback={mobileImage}
          alt={name}
          className="responsive-image"
        />
      </a>
    </SplideSlide>
  );
};

export default MainSliderBanner;
