/* eslint-disable max-len */
import React from 'react';
import Icon from 'helpers/svg-icon';

import {
  grey15,
  grey50,
  grey70,
  white,
  red,
  red85,
  blackBlue85,
} from '../../../assets/stylesheets/v2/abstracts/_variables.scss';

/**
 * Icon components.
 * @param {object} props - The props object.
 * @param {string} [props.fillColor='red'] - The fill color of the icon. With a default.
 * @param {string} [props.secondaryFillColor='white'] - The secondary fill color of the icon. With a default.
 * @param {string} [props.className] - Additional class name for styling purposes.
 * @param {number} [props.width=51] - The width of the icon. With a default.
 * @param {number} [props.height=25] - The height of the icon. With a default.
 * @returns {JSX.Element} React element representing the Icon.
 */

const AccountIconMobile = ({
  fillColor = grey70,
  className,
  width = 16,
  height = 16,
}) => (
  <Icon className={className} width={width} height={height} viewBox="0 0 16 16">
    <path
      fill={fillColor}
      d="M8 8c-1.1 0-2.042-.392-2.825-1.175C4.392 6.042 4 5.1 4 4s.392-2.042 1.175-2.825C5.958.392 6.9 0 8 0s2.042.392 2.825 1.175C11.608 1.958 12 2.9 12 4s-.392 2.042-1.175 2.825C10.042 7.608 9.1 8 8 8Zm-8 8v-2.8c0-.567.146-1.087.438-1.563.291-.475.679-.837 1.162-1.087a14.844 14.844 0 0 1 3.15-1.163A13.759 13.759 0 0 1 8 9c1.1 0 2.183.13 3.25.387 1.067.259 2.117.646 3.15 1.163.483.25.87.612 1.162 1.087.292.476.438.996.438 1.563V16H0Zm2-2h12v-.8a.973.973 0 0 0-.5-.85c-.9-.45-1.808-.787-2.725-1.012a11.6 11.6 0 0 0-5.55 0c-.917.225-1.825.562-2.725 1.012a.973.973 0 0 0-.5.85v.8Zm6-8c.55 0 1.02-.196 1.412-.588C9.804 5.021 10 4.55 10 4c0-.55-.196-1.02-.588-1.413A1.926 1.926 0 0 0 8 2c-.55 0-1.02.196-1.412.587A1.926 1.926 0 0 0 6 4c0 .55.196 1.02.588 1.412C6.979 5.804 7.45 6 8 6Z"
    />
  </Icon>
);

const AdminAccountIcon = ({
  fillColor = grey70,
  className,
  width = 20,
  height = 20,
  ...props
}) => (
  <Icon className={className} width={width} height={height} {...props}>
    <path
      fill={fillColor}
      d="M3.85 15.1c.85-.65 1.8-1.162 2.85-1.537A9.737 9.737 0 0 1 10 13c1.15 0 2.25.188 3.3.563 1.05.374 2 .887 2.85 1.537a7.73 7.73 0 0 0 1.363-2.325A7.838 7.838 0 0 0 18 10c0-2.217-.78-4.104-2.338-5.662C14.104 2.779 12.217 2 10 2s-4.104.78-5.662 2.338C2.779 5.896 2 7.783 2 10c0 .983.163 1.908.487 2.775.325.867.78 1.642 1.363 2.325ZM10 11c-.983 0-1.813-.338-2.487-1.012C6.838 9.313 6.5 8.483 6.5 7.5c0-.983.338-1.813 1.013-2.487C8.188 4.338 9.017 4 10 4c.983 0 1.813.338 2.488 1.013.675.675 1.012 1.504 1.012 2.487 0 .983-.338 1.813-1.012 2.488C11.813 10.663 10.983 11 10 11Zm0 9a9.738 9.738 0 0 1-3.9-.788 10.099 10.099 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 0 1 0 10c0-1.383.263-2.683.787-3.9a10.099 10.099 0 0 1 2.138-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0 1 10 0c1.383 0 2.683.263 3.9.787a10.098 10.098 0 0 1 3.175 2.138c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0 1 20 10a9.738 9.738 0 0 1-.788 3.9 10.098 10.098 0 0 1-2.137 3.175c-.9.9-1.958 1.613-3.175 2.137A9.738 9.738 0 0 1 10 20Zm0-2c.883 0 1.717-.13 2.5-.387a7.53 7.53 0 0 0 2.15-1.113 7.53 7.53 0 0 0-2.15-1.113A7.933 7.933 0 0 0 10 15c-.883 0-1.717.13-2.5.387A7.53 7.53 0 0 0 5.35 16.5a7.53 7.53 0 0 0 2.15 1.113A7.933 7.933 0 0 0 10 18Zm0-9c.433 0 .792-.142 1.075-.425.283-.283.425-.642.425-1.075 0-.433-.142-.792-.425-1.075C10.792 6.142 10.433 6 10 6c-.433 0-.792.142-1.075.425-.283.283-.425.642-.425 1.075 0 .433.142.792.425 1.075C9.208 8.858 9.567 9 10 9Z"
    />
  </Icon>
);

const AccountIconOutline = ({
  fillColor = grey70,
  className,
  width = 20,
  height = 20,
  ...props
}) => (
  <Icon className={className} width={width} height={height} {...props}>
    <path
      fill={fillColor}
      d="M10.9999 11.0007C9.53325 11.0007 8.2777 10.4784 7.23325 9.43398C6.18881 8.38954 5.66658 7.13398 5.66658 5.66732C5.66658 4.20065 6.18881 2.9451 7.23325 1.90065C8.2777 0.856207 9.53325 0.333984 10.9999 0.333984C12.4666 0.333984 13.7221 0.856207 14.7666 1.90065C15.811 2.9451 16.3333 4.20065 16.3333 5.66732C16.3333 7.13398 15.811 8.38954 14.7666 9.43398C13.7221 10.4784 12.4666 11.0007 10.9999 11.0007ZM0.333252 21.6673V17.934C0.333252 17.1784 0.527696 16.484 0.916585 15.8506C1.30547 15.2173 1.82214 14.734 2.46659 14.4007C3.84436 13.7118 5.24436 13.1951 6.66658 12.8506C8.08881 12.5062 9.53325 12.334 10.9999 12.334C12.4666 12.334 13.911 12.5062 15.3333 12.8506C16.7555 13.1951 18.1555 13.7118 19.5333 14.4007C20.1777 14.734 20.6944 15.2173 21.0833 15.8506C21.4721 16.484 21.6666 17.1784 21.6666 17.934V21.6673H0.333252ZM2.99992 19.0007H18.9999V17.934C18.9999 17.6895 18.9388 17.4673 18.8166 17.2673C18.6944 17.0673 18.5333 16.9118 18.3333 16.8006C17.1333 16.2007 15.9221 15.7507 14.6999 15.4507C13.4777 15.1507 12.2444 15.0007 10.9999 15.0007C9.75547 15.0007 8.52214 15.1507 7.29992 15.4507C6.0777 15.7507 4.86659 16.2007 3.66658 16.8006C3.46659 16.9118 3.30547 17.0673 3.18325 17.2673C3.06103 17.4673 2.99992 17.6895 2.99992 17.934V19.0007ZM10.9999 8.33398C11.7333 8.33398 12.361 8.07287 12.8833 7.55065C13.4055 7.02843 13.6666 6.40065 13.6666 5.66732C13.6666 4.93398 13.4055 4.30621 12.8833 3.78398C12.361 3.26176 11.7333 3.00065 10.9999 3.00065C10.2666 3.00065 9.63881 3.26176 9.11658 3.78398C8.59436 4.30621 8.33325 4.93398 8.33325 5.66732C8.33325 6.40065 8.59436 7.02843 9.11658 7.55065C9.63881 8.07287 10.2666 8.33398 10.9999 8.33398Z"
    />
  </Icon>
);

const Arrow = ({ fillColor = white, className, width = 16, height = 16 }) => (
  <Icon className={className} width={width} height={height}>
    <path
      fill={fillColor}
      d="M12.175 9H0V7h12.175l-5.6-5.6L8 0l8 8-8 8-1.425-1.4 5.6-5.6Z"
    />
  </Icon>
);

const AutocompleteIcon = ({
  fillColor = grey70,
  className,
  width = 18,
  height = 18,
}) => (
  <Icon className={className} width={width} height={height}>
    <path
      d="M16.6 18L10.3 11.7C9.8 12.1 9.225 12.4167 8.575 12.65C7.925 12.8833 7.23333 13 6.5 13C4.68333 13 3.14583 12.3708 1.8875 11.1125C0.629167 9.85417 0 8.31667 0 6.5C0 4.68333 0.629167 3.14583 1.8875 1.8875C3.14583 0.629167 4.68333 0 6.5 0C8.31667 0 9.85417 0.629167 11.1125 1.8875C12.3708 3.14583 13 4.68333 13 6.5C13 7.23333 12.8833 7.925 12.65 8.575C12.4167 9.225 12.1 9.8 11.7 10.3L18 16.6L16.6 18ZM6.5 11C7.75 11 8.8125 10.5625 9.6875 9.6875C10.5625 8.8125 11 7.75 11 6.5C11 5.25 10.5625 4.1875 9.6875 3.3125C8.8125 2.4375 7.75 2 6.5 2C5.25 2 4.1875 2.4375 3.3125 3.3125C2.4375 4.1875 2 5.25 2 6.5C2 7.75 2.4375 8.8125 3.3125 9.6875C4.1875 10.5625 5.25 11 6.5 11Z"
      fill={fillColor}
    />
  </Icon>
);

const AutocompleteCloseIcon = ({
  fillColor = grey70,
  className,
  width = 14,
  height = 14,
  ...props
}) => (
  <Icon className={className} width={width} height={height} {...props}>
    <path
      d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z"
      fill={fillColor}
    />
  </Icon>
);

const BasketWarningIcon = ({
  fillColor = grey70,
  className,
  width = 18,
  height = 18,
  ...props
}) => (
  <Icon className={className} width={width} height={height} {...props}>
    <path
      fill={fillColor}
      d="M0.833008 16.4993L9.99967 0.666016L19.1663 16.4993H0.833008ZM3.70801 14.8327H16.2913L9.99967 3.99935L3.70801 14.8327ZM9.99967 13.9993C10.2358 13.9993 10.4337 13.9195 10.5934 13.7598C10.7531 13.6 10.833 13.4021 10.833 13.166C10.833 12.9299 10.7531 12.732 10.5934 12.5723C10.4337 12.4125 10.2358 12.3327 9.99967 12.3327C9.76356 12.3327 9.56565 12.4125 9.40592 12.5723C9.2462 12.732 9.16634 12.9299 9.16634 13.166C9.16634 13.4021 9.2462 13.6 9.40592 13.7598C9.56565 13.9195 9.76356 13.9993 9.99967 13.9993ZM9.16634 11.4993H10.833V7.33268H9.16634V11.4993Z"
    />
  </Icon>
);

const BasketErrorIcon = ({
  fillColor = red85,
  className,
  width = 17,
  height = 17,
  ...props
}) => (
  <Icon className={className} width={width} height={height} {...props}>
    <path
      fill={fillColor}
      d="M8.99935 13.166C9.23546 13.166 9.43338 13.0862 9.5931 12.9264C9.75282 12.7667 9.83268 12.5688 9.83268 12.3327C9.83268 12.0966 9.75282 11.8987 9.5931 11.7389C9.43338 11.5792 9.23546 11.4993 8.99935 11.4993C8.76324 11.4993 8.56532 11.5792 8.4056 11.7389C8.24588 11.8987 8.16602 12.0966 8.16602 12.3327C8.16602 12.5688 8.24588 12.7667 8.4056 12.9264C8.56532 13.0862 8.76324 13.166 8.99935 13.166ZM8.16602 9.83268H9.83268V4.83268H8.16602V9.83268ZM8.99935 17.3327C7.84657 17.3327 6.76324 17.1139 5.74935 16.6764C4.73546 16.2389 3.85352 15.6452 3.10352 14.8952C2.35352 14.1452 1.75977 13.2632 1.32227 12.2493C0.884766 11.2355 0.666016 10.1521 0.666016 8.99935C0.666016 7.84657 0.884766 6.76324 1.32227 5.74935C1.75977 4.73546 2.35352 3.85352 3.10352 3.10352C3.85352 2.35352 4.73546 1.75977 5.74935 1.32227C6.76324 0.884766 7.84657 0.666016 8.99935 0.666016C10.1521 0.666016 11.2355 0.884766 12.2493 1.32227C13.2632 1.75977 14.1452 2.35352 14.8952 3.10352C15.6452 3.85352 16.2389 4.73546 16.6764 5.74935C17.1139 6.76324 17.3327 7.84657 17.3327 8.99935C17.3327 10.1521 17.1139 11.2355 16.6764 12.2493C16.2389 13.2632 15.6452 14.1452 14.8952 14.8952C14.1452 15.6452 13.2632 16.2389 12.2493 16.6764C11.2355 17.1139 10.1521 17.3327 8.99935 17.3327ZM8.99935 15.666C10.8605 15.666 12.4368 15.0202 13.7285 13.7285C15.0202 12.4368 15.666 10.8605 15.666 8.99935C15.666 7.13824 15.0202 5.56185 13.7285 4.27018C12.4368 2.97852 10.8605 2.33268 8.99935 2.33268C7.13824 2.33268 5.56185 2.97852 4.27018 4.27018C2.97852 5.56185 2.33268 7.13824 2.33268 8.99935C2.33268 10.8605 2.97852 12.4368 4.27018 13.7285C5.56185 15.0202 7.13824 15.666 8.99935 15.666Z"
    />
  </Icon>
);

const BasketIconFilled = ({
  fillColor = grey70,
  className,
  width = 27,
  height = 28,
}) => (
  <Icon className={className} width={width} height={height} viewBox="0 0 28 28">
    <path
      fill={fillColor}
      d="M8.333 27.334a2.568 2.568 0 0 1-1.883-.784 2.568 2.568 0 0 1-.783-1.883c0-.733.26-1.361.783-1.883A2.568 2.568 0 0 1 8.333 22c.734 0 1.361.261 1.884.784.522.522.783 1.15.783 1.883 0 .733-.261 1.361-.783 1.883a2.568 2.568 0 0 1-1.884.784Zm13.334 0a2.568 2.568 0 0 1-1.884-.784A2.568 2.568 0 0 1 19 24.667c0-.733.261-1.361.783-1.883A2.568 2.568 0 0 1 21.667 22c.733 0 1.36.261 1.883.784.522.522.783 1.15.783 1.883 0 .733-.26 1.361-.783 1.883a2.568 2.568 0 0 1-1.883.784Zm-15.734-24H25.6c.511 0 .9.227 1.167.683.266.456.277.917.033 1.383l-4.733 8.534a2.67 2.67 0 0 1-.984 1.033 2.594 2.594 0 0 1-1.35.367H9.8L8.333 18h16v2.667h-16c-1 0-1.755-.439-2.266-1.317-.511-.878-.534-1.75-.067-2.616l1.8-3.267L3 3.334H.333V.667h4.334l1.266 2.667Z"
    />
  </Icon>
);

const InStockIcon = ({
  fillColor = grey70,
  className,
  width = 14,
  height = 14,
}) => (
  <Icon className={className} width={width} height={height}>
    <path
      fill={fillColor}
      d="m7.833 12.834 5.875-5.875-1.166-1.167L7.833 10.5 5.458 8.125 4.292 9.292l3.541 3.542ZM9 17.334a8.115 8.115 0 0 1-3.25-.657 8.415 8.415 0 0 1-2.646-1.78 8.416 8.416 0 0 1-1.781-2.647A8.114 8.114 0 0 1 .667 9c0-1.152.218-2.236.656-3.25a8.415 8.415 0 0 1 1.781-2.646 8.415 8.415 0 0 1 2.646-1.78A8.115 8.115 0 0 1 9 .666c1.153 0 2.236.219 3.25.656a8.416 8.416 0 0 1 2.646 1.781 8.415 8.415 0 0 1 1.781 2.646A8.115 8.115 0 0 1 17.333 9a8.115 8.115 0 0 1-.656 3.25 8.416 8.416 0 0 1-1.781 2.646 8.416 8.416 0 0 1-2.646 1.781 8.115 8.115 0 0 1-3.25.657Zm0-1.667c1.861 0 3.438-.646 4.73-1.938 1.29-1.291 1.937-2.868 1.937-4.729 0-1.86-.646-3.437-1.938-4.729C12.438 2.98 10.861 2.334 9 2.334c-1.861 0-3.438.645-4.73 1.937-1.29 1.292-1.937 2.868-1.937 4.73 0 1.86.646 3.437 1.938 4.729C5.562 15.02 7.139 15.666 9 15.666Z"
    />
  </Icon>
);

const KeyboardArrowUp = ({
  fillColor = grey70,
  className,
  width = 12,
  height = 8,
}) => (
  <Icon className={className} width={width} height={height}>
    <path fill={fillColor} d="M6 2.8 1.4 7.4 0 6l6-6 6 6-1.4 1.4L6 2.8Z" />
  </Icon>
);

const KeyboardArrowDown = ({
  fillColor = grey70,
  className,
  width = 12,
  height = 8,
}) => (
  <Icon className={className} width={width} height={height}>
    <path fill={fillColor} d="m6 7.4-6-6L1.4 0 6 4.6 10.6 0 12 1.4l-6 6Z" />
  </Icon>
);

const LockIcon = ({
  fillColor = grey70,
  className,
  width = 24,
  height = 24,
}) => (
  <Icon className={className} width={width} height={height}>
    <path
      fill={fillColor}
      d="M2 21c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 0 19V9c0-.55.196-1.02.588-1.412A1.926 1.926 0 0 1 2 7h1V5c0-1.383.487-2.563 1.463-3.538C5.437.488 6.617 0 8 0s2.563.487 3.537 1.462C12.512 2.438 13 3.617 13 5v2h1c.55 0 1.02.196 1.412.588.392.391.588.862.588 1.412v10c0 .55-.196 1.02-.588 1.413A1.926 1.926 0 0 1 14 21H2Zm0-2h12V9H2v10Zm6-3c.55 0 1.02-.196 1.412-.588.392-.391.588-.862.588-1.412 0-.55-.196-1.02-.588-1.412A1.926 1.926 0 0 0 8 12c-.55 0-1.02.196-1.412.588A1.926 1.926 0 0 0 6 14c0 .55.196 1.02.588 1.412.391.392.862.588 1.412.588ZM5 7h6V5c0-.833-.292-1.542-.875-2.125A2.893 2.893 0 0 0 8 2c-.833 0-1.542.292-2.125.875A2.893 2.893 0 0 0 5 5v2Z"
    />
  </Icon>
);

const LoadingSpinner = ({
  fillColor = blackBlue85,
  secondaryFillColor = grey15,
  className,
  width = 32,
  height = 32,
}) => (
  <Icon
    className={className}
    width={width}
    height={height}
    viewBox="0 0 100 101">
    <path
      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
      fill={secondaryFillColor}
    />
    <path
      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
      fill={fillColor}
    />
  </Icon>
);

const OutOfStockIcon = ({
  fillColor = grey70,
  className,
  width = 14,
  height = 14,
}) => (
  <Icon className={className} width={width} height={height}>
    <path
      fill={fillColor}
      d="M9 13.167c.236 0 .434-.08.594-.24.16-.16.24-.357.24-.593a.806.806 0 0 0-.24-.594A.806.806 0 0 0 9 11.5a.806.806 0 0 0-.594.24.806.806 0 0 0-.24.594c0 .236.08.434.24.593.16.16.358.24.594.24Zm-.833-3.333h1.666v-5H8.167v5Zm.833 7.5a8.115 8.115 0 0 1-3.25-.657 8.415 8.415 0 0 1-2.646-1.78 8.416 8.416 0 0 1-1.781-2.647A8.114 8.114 0 0 1 .667 9c0-1.152.218-2.236.656-3.25a8.415 8.415 0 0 1 1.781-2.646 8.415 8.415 0 0 1 2.646-1.78A8.115 8.115 0 0 1 9 .666c1.153 0 2.236.219 3.25.656a8.416 8.416 0 0 1 2.646 1.781 8.415 8.415 0 0 1 1.781 2.646A8.115 8.115 0 0 1 17.333 9a8.115 8.115 0 0 1-.656 3.25 8.416 8.416 0 0 1-1.781 2.646 8.416 8.416 0 0 1-2.646 1.781 8.115 8.115 0 0 1-3.25.657Zm0-1.667c1.861 0 3.438-.646 4.73-1.938 1.29-1.291 1.937-2.868 1.937-4.729 0-1.86-.646-3.437-1.938-4.729C12.438 2.98 10.861 2.334 9 2.334c-1.861 0-3.438.645-4.73 1.937-1.29 1.292-1.937 2.868-1.937 4.73 0 1.86.646 3.437 1.938 4.729C5.562 15.02 7.139 15.666 9 15.666Z"
    />
  </Icon>
);

const WhiteCheck = ({
  fillColor = white,
  className,
  width = 10,
  height = 7,
  ...props
}) => (
  <Icon className={className} width={width} height={height} {...props}>
    <path
      fill={fillColor}
      d="M3.775 7 .925 4.15l.712-.713 2.138 2.138L8.363.987l.712.713-5.3 5.3Z"
    />
  </Icon>
);

const SaleIcon = ({
  fillColor = red,
  secondaryFillColor = white,
  className,
  width = 51,
  height = 25,
}) => (
  <Icon className={className} width={width} height={height}>
    <rect width={width} height={height} fill={fillColor} rx="4" />
    <path
      fill={secondaryFillColor}
      d="M12.26 17.216c-2.388 0-3.9-1.104-3.96-3.036h2.064c.108.972.744 1.404 1.896 1.404.792 0 1.536-.312 1.536-1.044 0-.78-.756-.948-2.052-1.248-1.74-.396-3.156-.828-3.156-2.688 0-1.848 1.512-2.688 3.48-2.688s3.36.912 3.492 2.664h-2.004c-.108-.696-.66-1.104-1.548-1.104-.84 0-1.404.36-1.404.924 0 .696.768.852 2.172 1.164 1.608.36 3.084.888 3.084 2.772 0 1.848-1.56 2.88-3.6 2.88ZM16.83 17l3.215-8.928h2.124L25.397 17h-2.16l-.564-1.704h-3.24L18.881 17H16.83Zm3.635-4.836-.552 1.668h2.292l-.54-1.668c-.384-1.224-.576-2.376-.576-2.376h-.024s-.312 1.5-.6 2.376ZM26.901 17V8.072h2.04v7.176h4.116V17h-6.156Zm7.842 0V8.072h6.697v1.704h-4.657v1.776h4.032v1.62h-4.032v2.076h4.74V17h-6.78Z"
    />
  </Icon>
);

const FavouriteIcon = ({
  fillColor = grey70,
  className,
  width = 20,
  height = 19,
  ...props
}) => (
  <Icon className={className} width={width} height={height} {...props}>
    <path
      fill={fillColor}
      d="m10 19-1.45-1.3c-1.683-1.516-3.075-2.825-4.175-3.925-1.1-1.1-1.975-2.087-2.625-2.962C1.1 9.938.646 9.133.387 8.4A6.725 6.725 0 0 1 0 6.15c0-1.566.525-2.875 1.575-3.925C2.625 1.175 3.933.65 5.5.65c.867 0 1.692.184 2.475.55A5.93 5.93 0 0 1 10 2.75a5.93 5.93 0 0 1 2.025-1.55A5.769 5.769 0 0 1 14.5.65c1.567 0 2.875.525 3.925 1.575C19.475 3.275 20 4.584 20 6.15c0 .767-.13 1.517-.387 2.25-.259.734-.713 1.538-1.363 2.413-.65.875-1.525 1.862-2.625 2.962s-2.492 2.409-4.175 3.925L10 19Zm0-2.7a109.28 109.28 0 0 0 3.95-3.687c1.033-1.025 1.85-1.917 2.45-2.675.6-.758 1.017-1.433 1.25-2.025.233-.592.35-1.18.35-1.763 0-1-.333-1.833-1-2.5-.667-.666-1.5-1-2.5-1a3.86 3.86 0 0 0-2.175.663C11.658 3.755 11.2 4.317 10.95 5h-1.9c-.25-.683-.708-1.245-1.375-1.687A3.86 3.86 0 0 0 5.5 2.65c-1 0-1.833.334-2.5 1-.667.667-1 1.5-1 2.5 0 .584.117 1.171.35 1.763.233.592.65 1.267 1.25 2.025s1.417 1.65 2.45 2.675A109.306 109.306 0 0 0 10 16.3Z"
    />
  </Icon>
);

const FavouriteIconFull = ({
  fillColor = grey70,
  className,
  width = 20,
  height = 19,
  ...props
}) => (
  <Icon className={className} width={width} height={height} {...props}>
    <path
      fill={fillColor}
      d="m9 15.5-1.208-1.083a94.208 94.208 0 0 1-3.48-3.27c-.916-.917-1.646-1.74-2.187-2.47-.542-.728-.92-1.399-1.136-2.01a5.604 5.604 0 0 1-.322-1.875c0-1.305.437-2.396 1.312-3.27C2.854.645 3.944.208 5.249.208c.723 0 1.41.153 2.063.458A4.941 4.941 0 0 1 9 1.96 4.942 4.942 0 0 1 10.687.667 4.808 4.808 0 0 1 12.75.21c1.305 0 2.396.437 3.27 1.312.876.875 1.313 1.966 1.313 3.271 0 .64-.107 1.264-.323 1.875-.215.611-.594 1.282-1.135 2.01-.542.73-1.271 1.553-2.188 2.47a94.209 94.209 0 0 1-3.479 3.27L9 15.501Z"
    />
  </Icon>
);

const GridViewIcon = ({
  fillColor = grey70,
  className,
  width = 18,
  height = 18,
}) => (
  <Icon className={className} width={width} height={height}>
    <path
      fill={fillColor}
      d="M0 8V0h8v8H0Zm0 10v-8h8v8H0ZM10 8V0h8v8h-8Zm0 10v-8h8v8h-8ZM2 6h4V2H2v4Zm10 0h4V2h-4v4Zm0 10h4v-4h-4v4ZM2 16h4v-4H2v4Z"
    />
  </Icon>
);

const MobileMenuIcon = ({
  fillColor = grey70,
  className,
  width = 24,
  height = 16,
}) => (
  <Icon className={className} width={width} height={height}>
    <path
      fill={fillColor}
      d="M0 16v-2.667h24V16H0Zm0-6.667V6.667h24v2.666H0Zm0-6.666V0h24v2.667H0Z"
    />
  </Icon>
);

const SplitScreenIcon = ({
  fillColor = grey70,
  className,
  width = 18,
  height = 18,
}) => (
  <Icon className={className} width={width} height={height}>
    <path
      fill={fillColor}
      d="M2 8C1.45 8 .98 7.804.587 7.412A1.926 1.926 0 0 1 0 6V2C0 1.45.196.98.588.587A1.926 1.926 0 0 1 2 0h14c.55 0 1.02.196 1.413.588C17.803.979 18 1.45 18 2v4c0 .55-.196 1.02-.587 1.412A1.926 1.926 0 0 1 16 8H2Zm0-2h14V2H2v4Zm0 12c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 0 16v-4c0-.55.196-1.02.588-1.412A1.926 1.926 0 0 1 2 10h14c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v4c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 16 18H2Zm0-2h14v-4H2v4Z"
    />
  </Icon>
);

const ArrowUpIcon = ({
  fillColor = grey70,
  className,
  width = 12,
  height = 8,
}) => (
  <Icon className={className} width={width} height={height}>
    <path fill={fillColor} d="M6 2.8 1.4 7.4 0 6l6-6 6 6-1.4 1.4L6 2.8Z" />
  </Icon>
);

const ArrowDownIcon = ({
  fillColor = grey70,
  className,
  width = 12,
  height = 8,
}) => (
  <Icon className={className} width={width} height={height}>
    <path fill={fillColor} d="m6 7.4-6-6L1.4 0 6 4.6 10.6 0 12 1.4l-6 6Z" />
  </Icon>
);

const ArrowLeftIcon = ({
  fillColor = grey70,
  className,
  width = 8,
  height = 12,
}) => (
  <Icon className={className} width={width} height={height}>
    <path fill={fillColor} d="M6 12 0 6l6-6 1.4 1.4L2.8 6l4.6 4.6L6 12Z" />
  </Icon>
);

const ArrowRightIcon = ({
  fillColor = grey70,
  className,
  width = 8,
  height = 12,
}) => (
  <Icon className={className} width={width} height={height}>
    <path fill={fillColor} d="M4.6 6 0 1.4 1.4 0l6 6-6 6L0 10.6 4.6 6Z" />
  </Icon>
);

const CheckoutLock = ({
  fillColor = grey70,
  className,
  width = 16,
  height = 21,
}) => (
  <Icon className={className} width={width} height={height}>
    <path
      fill={fillColor}
      d="M2 21c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 0 19V9c0-.55.196-1.02.588-1.412A1.926 1.926 0 0 1 2 7h1V5c0-1.383.487-2.563 1.463-3.538C5.437.488 6.617 0 8 0s2.563.487 3.537 1.462C12.512 2.438 13 3.617 13 5v2h1c.55 0 1.02.196 1.412.588.392.391.588.862.588 1.412v10c0 .55-.196 1.02-.588 1.413A1.926 1.926 0 0 1 14 21H2Zm0-2h12V9H2v10Zm6-3c.55 0 1.02-.196 1.412-.588.392-.391.588-.862.588-1.412 0-.55-.196-1.02-.588-1.412A1.926 1.926 0 0 0 8 12c-.55 0-1.02.196-1.412.588A1.926 1.926 0 0 0 6 14c0 .55.196 1.02.588 1.412.391.392.862.588 1.412.588ZM5 7h6V5c0-.833-.292-1.542-.875-2.125A2.893 2.893 0 0 0 8 2c-.833 0-1.542.292-2.125.875A2.893 2.893 0 0 0 5 5v2Z"
    />
  </Icon>
);

const CrossIcon = ({
  fillColor = grey70,
  className,
  width = 10,
  height = 10,
  ...props
}) => (
  <Icon className={className} width={width} height={height} {...props}>
    <path
      fill={fillColor}
      d="m1.266 9.666-.933-.933L4.066 5 .333 1.266l.933-.933L5 4.066 8.733.333l.933.933L5.933 5l3.733 3.733-.933.933L5 5.933 1.266 9.666Z"
    />
  </Icon>
);

const CircleCrossIcon = ({
  fillColor = grey70,
  className,
  width = 20,
  height = 20,
  ...props
}) => (
  <Icon className={className} width={width} height={height} {...props}>
    <path
      fill={fillColor}
      d="m6.4 15 3.6-3.6 3.6 3.6 1.4-1.4-3.6-3.6L15 6.4 13.6 5 10 8.6 6.4 5 5 6.4 8.6 10 5 13.6 6.4 15Zm3.6 5a9.738 9.738 0 0 1-3.9-.788 10.099 10.099 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 0 1 0 10c0-1.383.263-2.683.787-3.9a10.099 10.099 0 0 1 2.138-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0 1 10 0c1.383 0 2.683.263 3.9.787a10.098 10.098 0 0 1 3.175 2.138c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0 1 20 10a9.738 9.738 0 0 1-.788 3.9 10.098 10.098 0 0 1-2.137 3.175c-.9.9-1.958 1.613-3.175 2.137A9.738 9.738 0 0 1 10 20Zm0-2c2.233 0 4.125-.775 5.675-2.325C17.225 14.125 18 12.233 18 10c0-2.233-.775-4.125-2.325-5.675C14.125 2.775 12.233 2 10 2c-2.233 0-4.125.775-5.675 2.325C2.775 5.875 2 7.767 2 10c0 2.233.775 4.125 2.325 5.675C5.875 17.225 7.767 18 10 18Z"
    />
  </Icon>
);

const CheckBoxIcon = ({ className, width = 32, height = 32 }) => (
  <Icon className={className} width={width} height={height}>
    <rect
      width="31"
      height="31"
      x=".5"
      y=".5"
      stroke="#848484"
      fill="none"
      rx="3.5"
    />
  </Icon>
);

const CheckBoxCheckedIcon = ({
  fillColor = grey15,
  secondaryFillColor = white,
  className,
  width = 32,
  height = 32,
}) => (
  <Icon className={className} width={width} height={height}>
    <rect width="31" height="31" x=".5" y=".5" fill="#04122A" rx="3.5" />
    <rect width="31" height="31" x=".5" y=".5" stroke="#04122A" rx="3.5" />
    <mask id="a" width="24" height="24" x="4" y="4" maskUnits="userSpaceOnUse">
      <path fill={fillColor} d="M4 4h24v24H4z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill={secondaryFillColor}
        d="m13.55 22-5.7-5.7 1.425-1.425 4.275 4.275 9.175-9.175L24.15 11.4 13.55 22Z"
      />
    </g>
  </Icon>
);

const DeleteIcon = ({
  fillColor = grey70,
  className,
  width = 16,
  height = 18,
}) => (
  <Icon className={className} width={width} height={height}>
    <path
      fill={fillColor}
      d="M3 18c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 1 16V3H0V1h5V0h6v1h5v2h-1v13c0 .55-.196 1.02-.588 1.413A1.926 1.926 0 0 1 13 18H3ZM13 3H3v13h10V3ZM5 14h2V5H5v9Zm4 0h2V5H9v9Z"
    />
  </Icon>
);

const EditIcon = ({
  fillColor = grey70,
  className,
  width = 18,
  height = 18,
}) => (
  <Icon className={className} width={width} height={height}>
    <path
      fill={fillColor}
      d="M2 16h1.425L13.2 6.225 11.775 4.8 2 14.575V16Zm-2 2v-4.25L13.2.575c.2-.183.42-.325.663-.425.241-.1.495-.15.762-.15s.525.05.775.15c.25.1.467.25.65.45L17.425 2c.2.183.346.4.438.65a2.165 2.165 0 0 1 0 1.512 1.874 1.874 0 0 1-.438.663L4.25 18H0ZM12.475 5.525l-.7-.725L13.2 6.225l-.725-.7Z"
    />
  </Icon>
);

const ViewIcon = ({
  fillColor = grey70,
  className,
  width = 22,
  height = 15,
}) => (
  <Icon className={className} width={width} height={height}>
    <path
      fill={fillColor}
      d="M11 12c1.25 0 2.313-.438 3.188-1.313C15.062 9.813 15.5 8.75 15.5 7.5c0-1.25-.438-2.313-1.313-3.188C13.313 3.438 12.25 3 11 3c-1.25 0-2.313.438-3.188 1.313S6.5 6.25 6.5 7.5c0 1.25.438 2.313 1.313 3.188C8.688 11.562 9.75 12 11 12Zm0-1.8c-.75 0-1.387-.262-1.912-.787A2.604 2.604 0 0 1 8.3 7.5c0-.75.262-1.388.787-1.912A2.604 2.604 0 0 1 11 4.8c.75 0 1.387.263 1.912.788.525.524.788 1.162.788 1.912s-.262 1.387-.787 1.912A2.604 2.604 0 0 1 11 10.2Zm0 4.8c-2.433 0-4.65-.68-6.65-2.037C2.35 11.604.9 9.783 0 7.5c.9-2.283 2.35-4.104 4.35-5.463C6.35.68 8.567 0 11 0c2.433 0 4.65.68 6.65 2.038 2 1.358 3.45 3.179 4.35 5.462-.9 2.283-2.35 4.104-4.35 5.463C15.65 14.32 13.433 15 11 15Zm0-2a9.544 9.544 0 0 0 5.188-1.488A9.773 9.773 0 0 0 19.8 7.5a9.773 9.773 0 0 0-3.613-4.013A9.545 9.545 0 0 0 11 2a9.545 9.545 0 0 0-5.188 1.487A9.773 9.773 0 0 0 2.2 7.5a9.773 9.773 0 0 0 3.612 4.012A9.544 9.544 0 0 0 11 13Z"
    />
  </Icon>
);

const MinusIcon = ({
  fillColor = grey70,
  className,
  width = 14,
  height = 2,
}) => (
  <Icon viewBox="0 0 14 2" className={className} width={width} height={height}>
    <path fill={fillColor} d="M0 2V0h14v2H0Z" />
  </Icon>
);

const PlusIcon = ({
  fillColor = grey70,
  className,
  width = 14,
  height = 14,
}) => (
  <Icon viewBox="0 0 14 14" className={className} width={width} height={height}>
    <path fill={fillColor} d="M6 8H0V6h6V0h2v6h6v2H8v6H6V8Z" />
  </Icon>
);

const CategoryIcon = ({
  fillColor = blackBlue85,
  className,
  width = 20,
  height = 20,
}) => (
  <Icon viewBox="0 0 20 20" className={className} width={width} height={height}>
    <mask id="a" width="20" height="20" x="0" y="0" maskUnits="userSpaceOnUse">
      <path fill={fillColor} d="M0 0h20v20H0z" />
    </mask>
    <g>
      <path
        fill={fillColor}
        d="M5.417 9.167 10 1.667l4.583 7.5H5.417Zm9.166 9.167c-1.041 0-1.927-.365-2.656-1.094-.73-.73-1.094-1.615-1.094-2.656 0-1.042.365-1.927 1.094-2.657.73-.729 1.615-1.093 2.656-1.093 1.042 0 1.927.364 2.657 1.093.729.73 1.093 1.615 1.093 2.657 0 1.041-.364 1.927-1.093 2.656-.73.73-1.615 1.094-2.657 1.094ZM2.5 17.917V11.25h6.667v6.667H2.5Zm12.083-1.25c.584 0 1.077-.201 1.48-.604.402-.403.604-.896.604-1.48 0-.583-.202-1.076-.605-1.479a2.012 2.012 0 0 0-1.479-.604c-.583 0-1.076.202-1.479.604a2.012 2.012 0 0 0-.604 1.48c0 .583.201 1.076.604 1.479.403.403.896.604 1.48.604ZM4.167 16.25H7.5v-3.333H4.167v3.333ZM8.375 7.5h3.25L10 4.875 8.375 7.5Z"
      />
    </g>
  </Icon>
);

const AccountIcon = ({
  fillColor = blackBlue85,
  className,
  width = 24,
  height = 24,
}) => (
  <Icon viewBox="0 0 24 24" className={className} width={width} height={height}>
    <mask x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill={fillColor} />
    </mask>
    <g>
      <path
        d="M5.85 17.1C6.7 16.45 7.65 15.9375 8.7 15.5625C9.75 15.1875 10.85 15 12 15C13.15 15 14.25 15.1875 15.3 15.5625C16.35 15.9375 17.3 16.45 18.15 17.1C18.7333 16.4167 19.1875 15.6417 19.5125 14.775C19.8375 13.9083 20 12.9833 20 12C20 9.78333 19.2208 7.89583 17.6625 6.3375C16.1042 4.77917 14.2167 4 12 4C9.78333 4 7.89583 4.77917 6.3375 6.3375C4.77917 7.89583 4 9.78333 4 12C4 12.9833 4.1625 13.9083 4.4875 14.775C4.8125 15.6417 5.26667 16.4167 5.85 17.1ZM12 13C11.0167 13 10.1875 12.6625 9.5125 11.9875C8.8375 11.3125 8.5 10.4833 8.5 9.5C8.5 8.51667 8.8375 7.6875 9.5125 7.0125C10.1875 6.3375 11.0167 6 12 6C12.9833 6 13.8125 6.3375 14.4875 7.0125C15.1625 7.6875 15.5 8.51667 15.5 9.5C15.5 10.4833 15.1625 11.3125 14.4875 11.9875C13.8125 12.6625 12.9833 13 12 13ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C12.8833 20 13.7167 19.8708 14.5 19.6125C15.2833 19.3542 16 18.9833 16.65 18.5C16 18.0167 15.2833 17.6458 14.5 17.3875C13.7167 17.1292 12.8833 17 12 17C11.1167 17 10.2833 17.1292 9.5 17.3875C8.71667 17.6458 8 18.0167 7.35 18.5C8 18.9833 8.71667 19.3542 9.5 19.6125C10.2833 19.8708 11.1167 20 12 20ZM12 11C12.4333 11 12.7917 10.8583 13.075 10.575C13.3583 10.2917 13.5 9.93333 13.5 9.5C13.5 9.06667 13.3583 8.70833 13.075 8.425C12.7917 8.14167 12.4333 8 12 8C11.5667 8 11.2083 8.14167 10.925 8.425C10.6417 8.70833 10.5 9.06667 10.5 9.5C10.5 9.93333 10.6417 10.2917 10.925 10.575C11.2083 10.8583 11.5667 11 12 11Z"
        fill={fillColor}
      />
    </g>
  </Icon>
);

const BasketIcon = ({
  fillColor = blackBlue85,
  className,
  width = 24,
  height = 24,
  ...props
}) => (
  <Icon
    viewBox="0 0 24 24"
    className={className}
    width={width}
    height={height}
    {...props}>
    <mask x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill={fillColor} />
    </mask>
    <g>
      <path
        d="M7 22C6.45 22 5.97917 21.8042 5.5875 21.4125C5.19583 21.0208 5 20.55 5 20C5 19.45 5.19583 18.9792 5.5875 18.5875C5.97917 18.1958 6.45 18 7 18C7.55 18 8.02083 18.1958 8.4125 18.5875C8.80417 18.9792 9 19.45 9 20C9 20.55 8.80417 21.0208 8.4125 21.4125C8.02083 21.8042 7.55 22 7 22ZM17 22C16.45 22 15.9792 21.8042 15.5875 21.4125C15.1958 21.0208 15 20.55 15 20C15 19.45 15.1958 18.9792 15.5875 18.5875C15.9792 18.1958 16.45 18 17 18C17.55 18 18.0208 18.1958 18.4125 18.5875C18.8042 18.9792 19 19.45 19 20C19 20.55 18.8042 21.0208 18.4125 21.4125C18.0208 21.8042 17.55 22 17 22ZM6.15 6L8.55 11H15.55L18.3 6H6.15ZM5.2 4H19.95C20.3333 4 20.625 4.17083 20.825 4.5125C21.025 4.85417 21.0333 5.2 20.85 5.55L17.3 11.95C17.1167 12.2833 16.8708 12.5417 16.5625 12.725C16.2542 12.9083 15.9167 13 15.55 13H8.1L7 15H19V17H7C6.25 17 5.68333 16.6708 5.3 16.0125C4.91667 15.3542 4.9 14.7 5.25 14.05L6.6 11.6L3 4H1V2H4.25L5.2 4Z"
        fill={fillColor}
      />
    </g>
  </Icon>
);

const DashboardIcon = ({
  fillColor = blackBlue85,
  className,
  width = 24,
  height = 24,
}) => (
  <Icon viewBox="0 0 24 24" className={className} width={width} height={height}>
    <mask x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill={fillColor} />
    </mask>
    <g>
      <path
        d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM5 19H11V5H5V19ZM13 19H19V12H13V19ZM13 10H19V5H13V10Z"
        fill={fillColor}
      />
    </g>
  </Icon>
);

const ContactsIcon = ({
  fillColor = blackBlue85,
  className,
  width = 24,
  height = 24,
}) => (
  <Icon viewBox="0 0 24 24" className={className} width={width} height={height}>
    <mask x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill={fillColor} />
    </mask>
    <g>
      <path
        d="M4 23V21H20V23H4ZM4 3V1H20V3H4ZM12 13C12.8333 13 13.5417 12.7083 14.125 12.125C14.7083 11.5417 15 10.8333 15 10C15 9.16667 14.7083 8.45833 14.125 7.875C13.5417 7.29167 12.8333 7 12 7C11.1667 7 10.4583 7.29167 9.875 7.875C9.29167 8.45833 9 9.16667 9 10C9 10.8333 9.29167 11.5417 9.875 12.125C10.4583 12.7083 11.1667 13 12 13ZM4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H20C20.55 4 21.0208 4.19583 21.4125 4.5875C21.8042 4.97917 22 5.45 22 6V18C22 18.55 21.8042 19.0208 21.4125 19.4125C21.0208 19.8042 20.55 20 20 20H4ZM5.75 18C6.5 17.0667 7.40833 16.3333 8.475 15.8C9.54167 15.2667 10.7167 15 12 15C13.2833 15 14.4583 15.2667 15.525 15.8C16.5917 16.3333 17.5 17.0667 18.25 18H20V6H4V18H5.75ZM8.7 18H15.3C14.8167 17.6667 14.2958 17.4167 13.7375 17.25C13.1792 17.0833 12.6 17 12 17C11.4 17 10.8208 17.0833 10.2625 17.25C9.70417 17.4167 9.18333 17.6667 8.7 18ZM12 11C11.7167 11 11.4792 10.9042 11.2875 10.7125C11.0958 10.5208 11 10.2833 11 10C11 9.71667 11.0958 9.47917 11.2875 9.2875C11.4792 9.09583 11.7167 9 12 9C12.2833 9 12.5208 9.09583 12.7125 9.2875C12.9042 9.47917 13 9.71667 13 10C13 10.2833 12.9042 10.5208 12.7125 10.7125C12.5208 10.9042 12.2833 11 12 11Z"
        fill={fillColor}
      />
    </g>
  </Icon>
);

const CircleCheck = ({
  fillColor = white,
  className,
  width = 20,
  height = 21,
}) => (
  <Icon viewBox="0 0 20 21" className={className} width={width} height={height}>
    <path
      fill={fillColor}
      d="m8.6 15.1 7.05-7.05-1.4-1.4L8.6 12.3 5.75 9.45l-1.4 1.4L8.6 15.1Zm1.4 5.4a9.738 9.738 0 0 1-3.9-.788 10.099 10.099 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 0 1 0 10.5c0-1.383.263-2.683.787-3.9a10.099 10.099 0 0 1 2.138-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0 1 10 .5c1.383 0 2.683.262 3.9.788a10.098 10.098 0 0 1 3.175 2.137c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0 1 20 10.5a9.738 9.738 0 0 1-.788 3.9 10.098 10.098 0 0 1-2.137 3.175c-.9.9-1.958 1.613-3.175 2.137a9.738 9.738 0 0 1-3.9.788Zm0-2c2.233 0 4.125-.775 5.675-2.325C17.225 14.625 18 12.733 18 10.5c0-2.233-.775-4.125-2.325-5.675C14.125 3.275 12.233 2.5 10 2.5c-2.233 0-4.125.775-5.675 2.325C2.775 6.375 2 8.267 2 10.5c0 2.233.775 4.125 2.325 5.675C5.875 17.725 7.767 18.5 10 18.5Z"
    />
  </Icon>
);

const InvoiceIcon = ({
  fillColor = blackBlue85,
  className,
  width = 24,
  height = 24,
}) => (
  <Icon viewBox="0 0 24 24" className={className} width={width} height={height}>
    <mask x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill={fillColor} />
    </mask>
    <g>
      <path
        d="M6 22C5.16667 22 4.45833 21.7083 3.875 21.125C3.29167 20.5417 3 19.8333 3 19V16H6V2L7.5 3.5L9 2L10.5 3.5L12 2L13.5 3.5L15 2L16.5 3.5L18 2L19.5 3.5L21 2V19C21 19.8333 20.7083 20.5417 20.125 21.125C19.5417 21.7083 18.8333 22 18 22H6ZM18 20C18.2833 20 18.5208 19.9042 18.7125 19.7125C18.9042 19.5208 19 19.2833 19 19V5H8V16H17V19C17 19.2833 17.0958 19.5208 17.2875 19.7125C17.4792 19.9042 17.7167 20 18 20ZM9 9V7H15V9H9ZM9 12V10H15V12H9ZM17 9C16.7167 9 16.4792 8.90417 16.2875 8.7125C16.0958 8.52083 16 8.28333 16 8C16 7.71667 16.0958 7.47917 16.2875 7.2875C16.4792 7.09583 16.7167 7 17 7C17.2833 7 17.5208 7.09583 17.7125 7.2875C17.9042 7.47917 18 7.71667 18 8C18 8.28333 17.9042 8.52083 17.7125 8.7125C17.5208 8.90417 17.2833 9 17 9ZM17 12C16.7167 12 16.4792 11.9042 16.2875 11.7125C16.0958 11.5208 16 11.2833 16 11C16 10.7167 16.0958 10.4792 16.2875 10.2875C16.4792 10.0958 16.7167 10 17 10C17.2833 10 17.5208 10.0958 17.7125 10.2875C17.9042 10.4792 18 10.7167 18 11C18 11.2833 17.9042 11.5208 17.7125 11.7125C17.5208 11.9042 17.2833 12 17 12ZM6 20H15V18H5V19C5 19.2833 5.09583 19.5208 5.2875 19.7125C5.47917 19.9042 5.71667 20 6 20Z"
        fill={fillColor}
      />
    </g>
  </Icon>
);

const OrdersIcon = ({
  fillColor = blackBlue85,
  className,
  width = 24,
  height = 24,
}) => (
  <Icon viewBox="0 0 24 24" className={className} width={width} height={height}>
    <mask x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill={fillColor} />
    </mask>
    <g>
      <path
        d="M3.9998 20V7.1L2.0498 2.85L3.8498 2L6.1998 7.05H17.7998L20.1498 2L21.9498 2.85L19.9998 7.1V20H3.9998ZM9.9998 13H13.9998C14.2831 13 14.5206 12.9042 14.7123 12.7125C14.904 12.5208 14.9998 12.2833 14.9998 12C14.9998 11.7167 14.904 11.4792 14.7123 11.2875C14.5206 11.0958 14.2831 11 13.9998 11H9.9998C9.71647 11 9.47897 11.0958 9.2873 11.2875C9.09564 11.4792 8.9998 11.7167 8.9998 12C8.9998 12.2833 9.09564 12.5208 9.2873 12.7125C9.47897 12.9042 9.71647 13 9.9998 13ZM5.9998 18H17.9998V9.05H5.9998V18Z"
        fill={fillColor}
      />
    </g>
  </Icon>
);

const ReturnsIcon = ({
  fillColor = blackBlue85,
  className,
  width = 24,
  height = 24,
}) => (
  <Icon viewBox="0 0 24 24" className={className} width={width} height={height}>
    <mask x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill={fillColor} />
    </mask>
    <g>
      <path
        d="M12 16L13.4 14.6L11.825 13H16V11H11.825L13.4 9.4L12 8L8 12L12 16ZM5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H9.2C9.41667 2.4 9.77917 1.91667 10.2875 1.55C10.7958 1.18333 11.3667 1 12 1C12.6333 1 13.2042 1.18333 13.7125 1.55C14.2208 1.91667 14.5833 2.4 14.8 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM5 19H19V5H5V19ZM12 4.25C12.2167 4.25 12.3958 4.17917 12.5375 4.0375C12.6792 3.89583 12.75 3.71667 12.75 3.5C12.75 3.28333 12.6792 3.10417 12.5375 2.9625C12.3958 2.82083 12.2167 2.75 12 2.75C11.7833 2.75 11.6042 2.82083 11.4625 2.9625C11.3208 3.10417 11.25 3.28333 11.25 3.5C11.25 3.71667 11.3208 3.89583 11.4625 4.0375C11.6042 4.17917 11.7833 4.25 12 4.25Z"
        fill={fillColor}
      />
    </g>
  </Icon>
);

const InfoIcon = ({
  fillColor = grey50,
  className,
  width = 20,
  height = 20,
}) => (
  <Icon viewBox="0 0 24 24" className={className} width={width} height={height}>
    <path
      d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.5208 6.90417 10.7125 6.7125C10.9042 6.52083 11 6.28333 11 6C11 5.71667 10.9042 5.47917 10.7125 5.2875C10.5208 5.09583 10.2833 5 10 5C9.71667 5 9.47917 5.09583 9.2875 5.2875C9.09583 5.47917 9 5.71667 9 6C9 6.28333 9.09583 6.52083 9.2875 6.7125C9.47917 6.90417 9.71667 7 10 7ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z"
      fill={fillColor}
    />
  </Icon>
);

const TelephoneIcon = ({
  fillColor = grey50,
  className,
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
}) => (
  <Icon className={className} width={width} height={height} viewBox={viewBox}>
    <path
      d="M16.95 18C14.8667 18 12.8083 17.5458 10.775 16.6375C8.74167 15.7292 6.89167 14.4417 5.225 12.775C3.55833 11.1083 2.27083 9.25833 1.3625 7.225C0.454167 5.19167 0 3.13333 0 1.05C0 0.75 0.1 0.5 0.3 0.3C0.5 0.1 0.75 0 1.05 0H5.1C5.33333 0 5.54167 0.0791667 5.725 0.2375C5.90833 0.395833 6.01667 0.583333 6.05 0.8L6.7 4.3C6.73333 4.56667 6.725 4.79167 6.675 4.975C6.625 5.15833 6.53333 5.31667 6.4 5.45L3.975 7.9C4.30833 8.51667 4.70417 9.1125 5.1625 9.6875C5.62083 10.2625 6.125 10.8167 6.675 11.35C7.19167 11.8667 7.73333 12.3458 8.3 12.7875C8.86667 13.2292 9.46667 13.6333 10.1 14L12.45 11.65C12.6 11.5 12.7958 11.3875 13.0375 11.3125C13.2792 11.2375 13.5167 11.2167 13.75 11.25L17.2 11.95C17.4333 12.0167 17.625 12.1375 17.775 12.3125C17.925 12.4875 18 12.6833 18 12.9V16.95C18 17.25 17.9 17.5 17.7 17.7C17.5 17.9 17.25 18 16.95 18ZM3.025 6L4.675 4.35L4.25 2H2.025C2.10833 2.68333 2.225 3.35833 2.375 4.025C2.525 4.69167 2.74167 5.35 3.025 6ZM11.975 14.95C12.625 15.2333 13.2875 15.4583 13.9625 15.625C14.6375 15.7917 15.3167 15.9 16 15.95V13.75L13.65 13.275L11.975 14.95Z"
      fill={fillColor}
    />
  </Icon>
);

const HomeIcon = ({
  fillColor = white,
  className,
  width = 16,
  height = 18,
  viewBox = '0 0 16 18',
}) => (
  <Icon className={className} width={width} height={height} viewBox={viewBox}>
    <path
      d="M2 16H5V10H11V16H14V7L8 2.5L2 7V16ZM0 18V6L8 0L16 6V18H9V12H7V18H0Z"
      fill={fillColor}
    />
  </Icon>
);

const FilterIcon = ({
  fillColor = blackBlue85,
  className,
  width = 18,
  height = 18,
}) => (
  <Icon viewBox="0 0 18 18" className={className} width={width} height={height}>
    <path
      fill={fillColor}
      d="M8 18v-6h2v2h8v2h-8v2H8Zm-8-2v-2h6v2H0Zm4-4v-2H0V8h4V6h2v6H4Zm4-2V8h10v2H8Zm4-4V0h2v2h4v2h-4v2h-2ZM0 4V2h10v2H0Z"
    />
  </Icon>
);

export {
  AccountIcon,
  AccountIconMobile,
  Arrow,
  AdminAccountIcon,
  AccountIconOutline,
  AutocompleteIcon,
  AutocompleteCloseIcon,
  BasketIcon,
  BasketWarningIcon,
  BasketErrorIcon,
  BasketIconFilled,
  CheckoutLock,
  CircleCheck,
  FavouriteIcon,
  FavouriteIconFull,
  InStockIcon,
  KeyboardArrowUp,
  KeyboardArrowDown,
  LockIcon,
  LoadingSpinner,
  OutOfStockIcon,
  PlusIcon,
  WhiteCheck,
  SaleIcon,
  GridViewIcon,
  SplitScreenIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  CrossIcon,
  CircleCrossIcon,
  CheckBoxIcon,
  CheckBoxCheckedIcon,
  MinusIcon,
  MobileMenuIcon,
  CategoryIcon,
  DashboardIcon,
  ContactsIcon,
  InvoiceIcon,
  OrdersIcon,
  ReturnsIcon,
  DeleteIcon,
  EditIcon,
  ViewIcon,
  InfoIcon,
  TelephoneIcon,
  HomeIcon,
  FilterIcon,
};
