import React, { useReducer } from 'react';
import { QuantitySelector } from '@cityelectricalfactors/ui-components';
import { MinusIcon, PlusIcon } from 'components/shared/CommonSvgs';
import addToBasketReducer, {
  initialState,
} from 'components/AddProductToBasket/addToBasketReducer';
import Alert from 'components/Alert';
import GenericError from 'js_responses/generic_error';
import AddToBasket from 'components/UI/AddToBasket';
import useAddToBasket from 'hooks/useAddToBasket';

const AddProductToBasket = ({
  product,
  showPrice,
  isProductList,
  hideQuantityButtons,
  stockLevel,
  availabilityDate,
  quantity,
  lineId,
  isListView,
}) => {
  const {
    state,
    quantOnChange,
    quantOnBlur,
    increaseQuantity,
    reduceQuantity,
    handleAddButtonClick,
    handleModalAdd,
    closeBackOrderModal,
  } = useAddToBasket(product, quantity, stockLevel, lineId);

  const [dispatch] = useReducer(addToBasketReducer, initialState);

  const {
    count,
    alert,
    errorTitle,
    errorMessage,
    unauthMessage,
    updateDisabled,
    maximumGoodsTotal,
    price,
    loadingPrice,
    amount,
    message,
    loading,
    showModal,
    insufficientStock,
    basketSuccess,
  } = state;

  return errorMessage ? (
    <GenericError
      direction="flex-column"
      errorTitle={errorTitle}
      errorMessage={errorMessage}
      isProductList={isProductList}
    />
  ) : (
    <div className="col-12">
      {unauthMessage && (
        <div>
          <p className="text-center">
            <strong>{unauthMessage}</strong>
          </p>
        </div>
      )}
      <div className={`add-to-basket__content ${!lineId && 'row'}`}>
        <div
          className={`quantity-selector__wrapper ${isListView ? 'col-4' : 'col-3'} fl b-full br-4`}>
          <QuantitySelector
            stopButtonPropagation
            value={count}
            className="col-3"
            handleChange={quantOnChange}
            handleBlur={quantOnBlur}
            handleIncrement={increaseQuantity}
            handleDecrement={reduceQuantity}
            incrementIcon={<PlusIcon />}
            decrementIcon={<MinusIcon />}
            incrementValue={state.minimumOrder}
            hideButtons={hideQuantityButtons}
            disabled={loading}
          />
        </div>

        {!lineId && (
          <AddToBasket
            isListView={isListView}
            showModal={showModal}
            insufficientStock={insufficientStock}
            basketSuccess={basketSuccess}
            loading={loading}
            handleAddToBasket={handleAddButtonClick}
            handleAdd={handleModalAdd}
            closeBackOrderModal={closeBackOrderModal}
            stockLevel={stockLevel}
            availabilityDate={availabilityDate}
            product_id={product.id}
            price={price}
            count={count}
            isProductList={isProductList}
            product={product}
            clearError={() => {
              dispatch({ type: 'SET_MESSAGE', payload: '' });
              dispatch({ type: 'SET_ALERT', payload: false });
            }}
            disabled={
              updateDisabled || (loadingPrice && showPrice) || maximumGoodsTotal
            }
            itemSelected={() =>
              dispatch({ type: 'SET_SHOW_ERROR_FOR_PRODUCT', payload: true })
            }
          />
        )}
      </div>
      {alert && !lineId && (
        <div className="add-to-basket__error">
          <Alert showAmount amount={amount} message={message} />
        </div>
      )}
    </div>
  );
};

export { AddProductToBasket as default };
