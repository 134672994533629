import React from 'react';
import { Accordion } from '@cityelectricalfactors/ui-components';
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
} from 'components/shared/CommonSvgs';

const FAQGroup = ({ faqs }) => {
  const transpiledData = faqs.map(
    ({ id, question: title, answer: content }, index) => ({
      id,
      index,
      title,
      content,
    }),
  );

  return (
    <Accordion
      className="m-0 p-0 fl fl-1 faq__accordion"
      accordionItems={transpiledData}
      iconClosed={<KeyboardArrowDown />}
      iconOpen={<KeyboardArrowUp />}
    />
  );
};

export default FAQGroup;
