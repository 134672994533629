import React, { useReducer, useEffect } from 'react';
import usePricing from 'hooks/usePricing';
import { useCookie } from 'hooks/useCookies';

import { OutOfStockIcon } from 'components/shared/CommonSvgs';
import BulkBuyTable from 'components/shared/BulkBuyTable';
import PriceCalc from 'components/PriceCalc';
import ProductStock from 'components/ProductStock';

import { orange10 } from '../../../../assets/stylesheets/v2/abstracts/_variables.scss';

// Define initial state
const initialState = {
  price: '',
  rrp: '',
  priceError: false,
  breaks: {},
  strictlyNett: false,
};

// Define reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_PRICING_DATA':
      return {
        ...state,
        price: action.payload.price,
        rrp: action.payload.rrp,
        strictlyNett: action.payload.strictlyNett,
        breaks: action.payload.breaks,
        priceError: false,
      };
    case 'SET_PRICE_ERROR':
      return {
        ...state,
        priceError: true,
      };
    default:
      return state;
  }
};

const ProductPriceAndStock = ({
  productId,
  stockLevel,
  availabilityDate,
  signedIn,
  size,
  hideBreaks,
  compareProduct,
}) => {
  const [pricingCookieValue] = useCookie('show_pricing');
  const parsedPricingCookie = JSON.parse(pricingCookieValue);
  const { response: pricingResponse, setConfig: setPricingConfig } =
    usePricing();
  const [state, dispatch] = useReducer(reducer, initialState);

  const { price, priceError, strictlyNett, breaks } = state;

  useEffect(() => {
    if (signedIn) {
      setPricingConfig({
        method: 'GET',
        url: `/prices`,
        params: { products: productId },
      });
    }
  }, [productId, setPricingConfig]);

  useEffect(() => {
    if (!pricingResponse.result) return;

    const {
      success,
      data: { prices: pricingData },
    } = pricingResponse.result;

    if (success) {
      const currentProductPrice = pricingData[productId];
      const {
        cheapest_price: productCheapestPrice,
        rrp: productRrp,
        strictly_nett: productStrictlyNett,
        breaks: { data: productBreaks },
      } = currentProductPrice;

      dispatch({
        type: 'SET_PRICING_DATA',
        payload: {
          price: productCheapestPrice,
          rrp: productRrp,
          strictlyNett: productStrictlyNett,
          breaks: productBreaks,
        },
      });
    } else {
      dispatch({ type: 'SET_PRICE_ERROR' });
    }
  }, [pricingResponse.result, productId]);

  if (priceError) {
    return (
      <div className="current-price__stock row col-12 fl fd-col mtb-16">
        <OutOfStockIcon
          className="mr-4"
          width="20"
          height="20"
          fillColor={orange10}
        />
        <span className="current-price__stock__value ff--rc fw-500 td-none">
          There has been an error getting a price for this product. Please
          refresh.
        </span>
      </div>
    );
  }

  return (
    <>
      {parsedPricingCookie && signedIn && (
        <div className="row col-12 fl mb-10 ai-baseline product-price">
          <PriceCalc
            compareProduct={compareProduct}
            price={price}
            strictlyNett={strictlyNett}
            isProductShow
            showPriceBox
          />
        </div>
      )}

      <div className="product-stock-status">
        <ProductStock
          size={size || 'large'}
          stockLevel={stockLevel}
          availabilityDate={availabilityDate}
        />
      </div>

      {!!breaks.length &&
        price &&
        parsedPricingCookie &&
        signedIn &&
        !hideBreaks && (
          <>
            <div className="tb-divider" />
            <BulkBuyTable productBreaks={breaks} />
          </>
        )}
    </>
  );
};

export default ProductPriceAndStock;
