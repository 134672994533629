import React, { useState, useEffect } from 'react';
import { Button } from '@cityelectricalfactors/ui-components';
import useBasketStore from 'stores/basketStore/useBasketStore';
import useToastStore from 'stores/useToastStore';

import { useHTTP } from 'hooks/useRequest';
import { useCookie } from 'hooks/useCookies';

import AddProductToBasket from 'components/AddProductToBasket';
import { BasketWarningIcon } from 'components/shared/CommonSvgs';
import BasketStockWarning from './BasketStockWarning';

const DiscountNotification = ({ text, breakAmount, value }) => (
  <div className="bulk-discount-reduction fl p-8 br-4 mb-16 m-mb-0">
    <div className="bulk-discount-reduction__text mr-8 fw-700">
      {breakAmount}
    </div>
    <div className="bulk-discount-reduction__text mr-8 fw-700">{text}</div>
    <div className="bulk-discount-reduction__value c--blue">-£{value}</div>
  </div>
);

const GoodsBeforeDiscount = ({ value }) => (
  <div className="goods-before-discount fl mr-8 m-mr-0">
    <div className="goods-before-discount__value td-lt">£{value}</div>
  </div>
);

const LineButton = ({ action, text }) => (
  <Button
    handleClick={action}
    className="basket-actions__action bg-none b-none ff-body c--black-blue td-u plr-0 m-plr-6">
    {text}
  </Button>
);

const PriceContainer = ({ price }) => (
  <div className="item-price__unit-price m-plr-8 fs-16 fw-700">
    &pound;{price}
  </div>
);

const BasketLine = ({
  line_id: id,
  quantity,
  goods,
  unit: unitPrice,
  product,
  line_break: {
    break: lineBreakAmount,
    goods_before_discount: goodsBeforeDiscount,
    saving,
  },
  product: {
    stock_level: stock,
    id: productId,
    description_short: description,
    brand,
    catalogue,
    image_url: imageUrl,
    url,
    white_goods: whiteGoods,
  },
  whiteGoodsMinOrder,
  containsWhiteGoods,
}) => {
  const [isBreakApplied, setIsBreakApplied] = useState(false);
  const { addToast } = useToastStore();
  const { removeBasketLine, setCurrentBasket, setBasketUpdateMessage } =
    useBasketStore(state => ({
      removeBasketLine: state.removeBasketLine,
      setCurrentBasket: state.setCurrentBasket,
      setBasketUpdateMessage: state.setBasketUpdateMessage,
    }));

  const [pricingCookieValue] = useCookie('show_pricing');
  const parsedCookieValue = JSON.parse(pricingCookieValue);
  const [requestConfig, setRequestConfig] = useState({
    url: null,
    method: null,
    headers: {
      Accept: 'application/json',
    },
  });

  const { data, error } = useHTTP(requestConfig);

  useEffect(() => {
    if (data) {
      const { basket, message } = data;
      addToast(message);
      removeBasketLine(id);
      setCurrentBasket(basket);
      setBasketUpdateMessage(message);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      const { message } = data;
      setBasketUpdateMessage(message);
    }
  }, [error]);

  const removeLine = () => {
    setRequestConfig({
      url: `/basket_lines/${id}`,
      method: 'delete',
      headers: {
        Accept: 'application/json',
      },
    });
  };

  const saveForLater = () => {
    setRequestConfig({
      url: `/basket_lines/${id}/favourite_product_line/${productId}`,
      method: 'post',
      headers: {
        Accept: 'application/json',
      },
    });
  };

  const showLineBreak = () => {
    const breakApplied = quantity >= +lineBreakAmount;
    setIsBreakApplied(breakApplied);
  };

  useEffect(() => {
    showLineBreak();
  }, [lineBreakAmount, quantity]);

  const BasketLineContent = () => (
    <>
      <div className="fl fl-grow-1">
        <a href={url} className="col-4 basket-line__basket-img mr-16">
          <img
            src={imageUrl}
            height="100"
            width="100"
            className="width-100"
            alt=""
          />
          <span className="sr-only">Product Image</span>
        </a>
        <div className="m-fl fl-grow-1 fl-1">
          <div className="basket-line__description-container fl fl-1 fl-col ai-fs m-mt-16">
            <a href={url} className="td-none c--black-blue fw-700 fs-16 mb-12">
              {description}
            </a>
            <div className="m-fl fs-14">
              <div className="product-brand mb-8 m-mb-0">{brand}</div>
              {brand && <div className="lr-divider" />}
              <span className="mr-5">Part Code</span>
              <span className="fw-700">{catalogue}</span>
            </div>
            {whiteGoods && (
              <div
                className={`premium-delivery-error br-2 fl ai-c jc-fs plr-8 pt-10 pb-10 mt-16
                  ${containsWhiteGoods?.goods > whiteGoodsMinOrder ? 'd-none' : ''}`}>
                <p className="fl ai-c m-0">
                  <BasketWarningIcon className="mr-4" />
                  This requires premium delivery
                </p>
              </div>
            )}
          </div>
          <div className="fl fl-1 m-ai-fe fl-col mr-24">
            {parsedCookieValue && (
              <div className="fl m-ai-fe mb-12 mt-16">
                {isBreakApplied && (
                  <GoodsBeforeDiscount value={goodsBeforeDiscount} />
                )}
                <PriceContainer price={unitPrice} />
              </div>
            )}

            {parsedCookieValue && isBreakApplied && (
              <DiscountNotification
                text={` Price Break`}
                breakAmount={lineBreakAmount}
                value={saving}
              />
            )}
          </div>
        </div>
      </div>

      <div className="item-prices fl fl-col">
        <div className="basket-line__pricing m-fl ai-c jc-sb mb-16 m-mb-40">
          <div className="col-3 mr-10">
            <AddProductToBasket
              stockLevel={stock}
              isProductList
              productId={productId}
              product={product}
              quantity={quantity}
              fieldName="quantity-input"
              showVat={false}
              lineId={id}
            />
          </div>

          {parsedCookieValue && <PriceContainer price={goods} />}
        </div>
        <div className="basket-line__actions fl m-as-fe">
          <LineButton action={() => removeLine()} text="Remove" />
          <div className="lr-divider" />
          <LineButton action={() => saveForLater()} text="Save for Later" />
        </div>
      </div>
    </>
  );

  return (
    <>
      <div
        className={`basket__line m-fl jc-sb p-24
          ${quantity > stock && 'fw-w basket__line--border-off'}`}
        key={id}>
        {data?.message ? (
          <div className="col-12">{data.message}</div>
        ) : (
          <BasketLineContent />
        )}
      </div>
      {quantity > stock && (
        <BasketStockWarning quantity={quantity} stock={stock} />
      )}
    </>
  );
};

export default BasketLine;
