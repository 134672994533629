import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@cityelectricalfactors/ui-components';
import useBasketStore from 'stores/basketStore/useBasketStore';
import { useHTTP } from 'hooks/useRequest';
import AddProductToBasket from 'components/AddProductToBasket';

const LineButton = ({ action, text }) => (
  <LineActionButton
    handleClick={action}
    className="bg-none b-none ff-body c--black-blue td-u">
    {text}
  </LineActionButton>
);

const BasketPreviewProduct = ({
  signedIn,
  pricingCookieValue,
  line_id: id,
  quantity,
  unit,
  product,
  product: {
    stock_level: stock,
    id: productId,
    description_short: descriptionShort,
    catalogue,
    image_url: imageUrl,
    url,
  },
}) => {
  const {
    removeBasketLine,
    setCurrentBasket,
    setBasketGoodsTotal,
    setBasketUpdateMessage,
  } = useBasketStore(state => ({
    removeBasketLine: state.removeBasketLine,
    setCurrentBasket: state.setCurrentBasket,
    setBasketGoodsTotal: state.setBasketGoodsTotal,
    setBasketUpdateMessage: state.setBasketUpdateMessage,
  }));
  const [requestConfig, setRequestConfig] = useState({
    url: null,
    method: null,
    headers: {
      Accept: 'application/json',
    },
  });

  const { data, error } = useHTTP(requestConfig);

  useEffect(() => {
    if (data) {
      const { goods_total: newBasketGoodsTotal, message, basket } = data;
      removeBasketLine(id);
      setCurrentBasket(basket);
      setBasketGoodsTotal(newBasketGoodsTotal);
      setBasketUpdateMessage(message);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      const { message } = data;
      setBasketUpdateMessage(message);
    }
  }, [error]);

  const removeLine = e => {
    e.stopPropagation();
    setRequestConfig({
      url: `/basket_lines/${id}`,
      method: 'delete',
      headers: {
        Accept: 'application/json',
      },
    });
  };
  return (
    <div className="user-favourite__product fl mb-16 td-none">
      <div className="user-favourite__product-image mr-16">
        {imageUrl !== '' && (
          <a href={url} aria-label="Product">
            <img src={imageUrl} alt="" width="60" height="60" />
          </a>
        )}
      </div>

      <div className="dropdown__product-content">
        <p className="fs-14 ff--rc td-none mt-0 mb-0">
          <a href={url} className="dropdown__product-title ">
            {descriptionShort}
          </a>
        </p>
        <p className="m-0">
          <a href={url} className="dropdown__product-code">
            <span className="fs-12">
              Part Code: <strong className="ml-6">{catalogue}</strong>
            </span>
          </a>
        </p>
        <div className="basket-preview__line-actions fl ai-c mt-16">
          <AddProductToBasket
            stockLevel={stock}
            isProductList
            isBasketPreview
            productId={productId}
            product={product}
            quantity={quantity}
            fieldName="quantity-input"
            showVat={false}
            lineId={id}
          />
          <LineButton action={e => removeLine(e)} text="Remove" />
        </div>
      </div>
      <div className="fl fl-col fs-14 ml-8">
        {pricingCookieValue && signedIn && (
          <div className="fl jc-fe">£{unit}</div>
        )}
      </div>
    </div>
  );
};

const LineActionButton = styled(Button)`
  align-self: flex-start;
`;

export default BasketPreviewProduct;
