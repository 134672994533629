import React, { useRef } from 'react';
import BackOrderModal from 'components/AddProductToBasket/BackOrderModal';
import LoadingSpinnerComponent from 'components/shared/LoadingSpinnerComponent';

const AddToBasket = ({
  count,
  product_id: productId,
  disabled,
  product,
  stockLevel,
  handleAdd,
  handleAddToBasket,
  closeBackOrderModal,
  loading,
  showModal,
  insufficientStock,
  basketSuccess,
  isListView,
}) => {
  const addRef = useRef(null);

  const basketSuccessClass =
    basketSuccess === true ? 'add-to-basket__button--submit_success' : '';

  return (
    <div className={`add-to-basket__button ${isListView ? 'col-8' : 'col-9'}`}>
      <button
        disabled={count === 0 || Number.isNaN(count) || disabled}
        type="button"
        ref={addRef}
        className={`add-to-basket__button--submit ${basketSuccessClass} 
        btn btn-blue col-9 br-4 width-100 fw-700`}
        onClick={() => handleAddToBasket(productId)}>
        {loading ? (
          <LoadingSpinnerComponent className="fl fl-1 jc-c" />
        ) : (
          <span>{basketSuccess ? 'Added to Basket' : 'Add to Basket'}</span>
        )}
      </button>
      <BackOrderModal
        productDescription={product.description}
        modalIsOpen={showModal}
        closeModal={() => closeBackOrderModal()}
        stockLevel={stockLevel}
        quantity={count}
        insufficientStock={insufficientStock}
        successCallback={() => handleAdd()}
      />
    </div>
  );
};

export { AddToBasket as default };
