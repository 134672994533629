import React, { useState, useEffect } from 'react';
import { useHTTP } from 'hooks/useRequest';
import useUserFavouritesStore from 'stores/userFavouritesStore/useUserFavouritesStore';
import useToastStore from 'stores/useToastStore';
import { FavouriteIcon, FavouriteIconFull } from 'components/shared/CommonSvgs';

const AddToFavourites = ({ product, url }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [requestUrl, setRequestUrl] = useState(null);
  const [actionType, setActionType] = useState(null);

  const { addToast } = useToastStore();
  const {
    addFavouriteProduct,
    removeFavouriteProduct,
    userFavouritesProducts,
  } = useUserFavouritesStore(state => ({
    addFavouriteProduct: state.addFavouriteProduct,
    removeFavouriteProduct: state.removeFavouriteProduct,
    userFavouritesProducts: state.userFavouritesProducts,
  }));

  const productIsFavourite = userFavouritesProducts.some(
    item => item.id === product.id,
  );

  const { error, data } = useHTTP({
    url: requestUrl,
    method: 'get',
    headers: {
      Accept: 'application/json',
    },
  });

  useEffect(() => {
    if (error) {
      addToast('Error processing request.', null, 'decline');
      setIsProcessing(false);
    }

    if (data) {
      if (actionType === 'add') {
        addToast('Product added to Favourites.');
      } else if (actionType === 'remove') {
        addToast('Product removed from Favourites.');
      }
      setIsProcessing(false);
    }
  }, [data, error, addToast, actionType]);

  const handleClick = () => {
    if (isProcessing) return;

    setIsProcessing(true);

    if (productIsFavourite) {
      setActionType('remove');
      setRequestUrl(`/catalogue/products/${product.id}/remove_from_favourite`);
      removeFavouriteProduct(product.id);
    } else {
      setActionType('add');
      setRequestUrl(url);
      addFavouriteProduct(product);
    }
  };

  return (
    <button
      type="button"
      className={`user-favourites user-favourites__add-to-favourites 
        ${productIsFavourite ? 'user-favourites__product-added' : ''}
        fl ai-c jc-c br-100 box-shadow-on-hover fw-400 td-none`}
      aria-label="Add to User Favourites"
      onClick={handleClick}
      disabled={isProcessing}>
      {productIsFavourite ? (
        <FavouriteIconFull width="18" height="16" viewBox="0 0 18 16" />
      ) : (
        <FavouriteIcon viewBox="0 0 20 20" />
      )}
    </button>
  );
};

export default AddToFavourites;
