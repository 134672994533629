import React, { useEffect, useState } from 'react';
import { Button } from '@cityelectricalfactors/ui-components';
import { useHTTP } from 'hooks/useRequest';
import useBasketStore from 'stores/basketStore/useBasketStore';
import Delivery from './panels/Delivery';
import DeliveryOptions from './panels/DeliveryOptions';
import Confirm from './panels/Confirm';
import OrderComplete from './OrderComplete';

const Checkout = ({
  allowedToDeliverAnywhere,
  accountInvoiceAddress,
  addresses,
  currentBasket,
}) => {
  const {
    setCurrentStep,
    currentStep,
    setCurrentBasket,
    orderComplete,
    setAddressBook,
    addressBook,
    resetDeliveryAddresses,
  } = useBasketStore(state => ({
    resetDeliveryAddresses: state.resetDeliveryAddresses,
    setCurrentStep: state.setCurrentStep,
    currentStep: state.currentStep,
    setCurrentBasket: state.setCurrentBasket,
    orderComplete: state.orderComplete,
    setAddressBook: state.setAddressBook,
    addressBook: state.addressBook,
  }));

  const [requestConfig, setRequestConfig] = useState({
    url: null,
    method: null,
  });

  const { data } = useHTTP(requestConfig);

  const handleEdit = step => {
    setRequestConfig({
      url: '/checkout',
      method: 'put',
      data: { basket: { step: step.toLowerCase() } },
    });
  };

  useEffect(() => {
    if (data) {
      const { basket } = data;
      setCurrentBasket(basket);
      setCurrentStep(basket.step.toUpperCase());
      setRequestConfig({
        url: null,
        method: null,
      });
    }
  }, [data]);

  useEffect(() => {
    setRequestConfig({
      url: '/checkout',
      method: 'put',
      data: { basket: { step: 'delivery_address' } },
    });

    setCurrentBasket(currentBasket);
    setAddressBook(addresses);
    resetDeliveryAddresses();
  }, []);

  const deliveryPanelProps = {
    allowedToDeliverAnywhere,
    accountInvoiceAddress,
    addressBook,
  };

  const panels = [
    {
      step: 'DELIVERY_ADDRESS',
      title: 'Delivery Address',
      component: <Delivery {...deliveryPanelProps} />,
    },
    {
      step: 'ADDITIONAL_INFORMATION',
      title: 'Delivery Options',
      component: <DeliveryOptions />,
    },
    {
      step: 'CONFIRM',
      title: 'Confirm',
      component: <Confirm invoiceAddress={accountInvoiceAddress} />,
    },
  ];

  const renderPanel = panelStep => {
    const panel = panels.find(p => p.step === panelStep);
    return panel ? panel.component : null;
  };

  const getCurrentStepIndex = () =>
    panels.findIndex(panel => panel.step === currentStep);

  return orderComplete.complete ? (
    <OrderComplete url={orderComplete.url} />
  ) : (
    <div>
      {panels.map(({ step, title }, index) => (
        <div className="checkout__panel" key={step}>
          <h2 className="col-12 fl ai-c m-0">
            <span
              className="checkout__step br-100 b-full b-colour--grey20
            mr-10 fl ai-c jc-c fs-16">
              {index + 1}
            </span>
            {title}
            {getCurrentStepIndex() > index && (
              <Button
                className="checkout__step-edit br-8 plr-8"
                handleClick={() => handleEdit(step)}>
                Edit
              </Button>
            )}
          </h2>
          {currentStep === step && renderPanel(step)}
        </div>
      ))}
    </div>
  );
};

export default Checkout;
