import React from 'react';
import useDropdown from 'hooks/useDropdown';

const Dropdown = ({
  pillIndex,
  dropdownAction: DropdownAction,
  children,
  count,
  className,
  onBasketPage,
}) => {
  const { isOpen } = useDropdown(pillIndex);

  return (
    <div className={`header-dropdown-button-wrapper ${className}`}>
      <DropdownAction />
      {!!count && !onBasketPage && (
        <div
          className={`header-dropdown-button-dropdown 
          ${isOpen && 'header-dropdown-button-dropdown--is-open'}`}>
          <div
            className="header-dropdown-button-dropdown__content"
            role="menu"
            aria-orientation="vertical">
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
