import { create } from 'zustand';

const pricingStore = (set, get) => ({
  autocompletePrices: [],
  productListPrices: [],
  pricingError: {},
  setAutocompleteProductPrices: autocompletePrices => {
    set(() => ({
      autocompletePrices,
    }));
  },
  setProductListPrices: productListPrices => {
    set(() => ({
      productListPrices,
    }));
  },
  setProductPricingError: pricingError => {
    set(() => ({
      pricingError,
    }));
  },
  // addBasketLine: product => {
  //   const products = get().userBasketLines;
  //   const isDuplicate = products.some(item => item.id === product.id);

  //   set(() => ({
  //     userBasketLines: isDuplicate ? products : [...products, product],
  //   }));
  // },
  // removeBasketLine: id => {
  //   const products = get().userBasketLines;
  //   const updatedProducts = products.filter(product => product.id !== id);

  //   set(() => ({
  //     userBasketLines: updatedProducts,
  //   }));
  // },
});

const usePricingStore = create(pricingStore);

export default usePricingStore;
