import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const SummaryCardText = ({ text, content }) => (
  <p className="mt-0 mr-16 m-mr-40 fb-40 m-fl-auto">
    {text}: <span className="d-block mt-10 fw-700">{content || '-'}</span>
  </p>
);

const OrderSummaryCard = ({
  date,
  price,
  orderNumber,
  purchaseOrder,
  products,
  status,
}) => (
  <div className="order-summary-card fl fl-col jc-sb p-24 b-full br-4 b-colour--grey mb-8">
    <div className="order-details fl jc-sb fl-col m-fl-row">
      <div className="order-info fl fw-w">
        <SummaryCardText text="Order number" content={orderNumber} />
        <SummaryCardText
          text="Order placed"
          content={date.toLocaleDateString()}
        />
        <SummaryCardText text="Purchase Order" content={purchaseOrder} />
        <SummaryCardText text="Goods Total" content={`£${price}`} />
      </div>
      <div className="fl">
        <div className="order-summary-status p-8 mb-16 m-mb-52 fs-14">
          {status}
        </div>
      </div>
    </div>
    <div className="order-actions fl jc-sb fl-col m-fl-row">
      <div className="order-summary-card__product-images fl fw-w mb-16 m-mb-0 m-fb-60">
        {products
          .slice(0, 5)
          .map(
            ({
              product_image_url: src,
              product_name: name,
              product_url: url,
            }) => {
              const uniqueId = uuidv4();
              return (
                <a href={url} key={uniqueId}>
                  <img
                    className="mr-16"
                    src={src}
                    width="60"
                    height="60"
                    alt={name}
                  />
                </a>
              );
            },
          )}
        {products.length > 5 && (
          <p className="fs-14 fl ai-c">+{products.length - 5} more</p>
        )}
      </div>

      <div className="fl ai-fe">
        <a
          className="details-button p-16 btn btn-black-blue br-4 fw-700"
          href={`/account/orders/${orderNumber}`}>
          See more details
        </a>
      </div>
    </div>
  </div>
);

export default OrderSummaryCard;
