import React from 'react';
import { InStockIcon, OutOfStockIcon } from '../shared/CommonSvgs';
import {
  successGreen,
  orange10,
} from '../../../assets/stylesheets/v2/abstracts/_variables.scss';
import CountdownTimer from './CountdownTimer';
/**
 * ProductStock Component
 *
 * @param {number} stockLevel - The stock level of the product.
 * @param {string} availabilityDate - The availability date of stock.
 * @param {string} size - The visual size of the component. ('large'|'medium'|'small'|'text')
 */

const ProductStock = ({ className, stockLevel, availabilityDate, size }) => {
  const inStock = stockLevel > 0;
  const isNotTextSize = size !== 'text';
  const isLarge = size === 'large';

  return (
    <div
      className={`product-stock fl p-4 fs-14 ${
        inStock && isNotTextSize
          ? `b-full
      b-colour--green br-2 ${isLarge ? 'bgc--green' : ''}`
          : 'b-full b-colour--orange bgc--orange br-2'
      } ${className || ''}`}>
      {inStock ? (
        <>
          <InStockIcon
            className="mr-4"
            width="20"
            height="20"
            fillColor={successGreen}
          />
          <div className="product-stock__number-wrapper fl fl-col nm np">
            <span className="product-stock__number-content">
              <span className="product-stock__number-value mr-4 fw-700 c--green">
                {stockLevel}
              </span>
              {isNotTextSize &&
                (size === 'small' ? (
                  <span>in stock</span>
                ) : (
                  <span className={`${isLarge ? 'fw-700' : ''}`}>
                    in stock for Delivery
                  </span>
                ))}
            </span>
            {isLarge && <CountdownTimer />}
          </div>
        </>
      ) : (
        <>
          <OutOfStockIcon
            className="mr-4"
            width="20"
            height="20"
            fillColor={orange10}
          />
          <p className="product-stock__number-wrapper fl fl-col nm np">
            <span className="product-stock__number-content">
              <span className="product-stock__number-value mr-4">
                Out of Stock
              </span>
            </span>
            {isLarge && (
              <>
                <span>
                  <span>Estimated stock availability: </span>
                  <span className="fw-700">
                    {availabilityDate || 'To be confirmed'}
                  </span>
                </span>
              </>
            )}
          </p>
        </>
      )}
    </div>
  );
};

export default ProductStock;
