import React, { useState, useEffect } from 'react';
import { Button } from '@cityelectricalfactors/ui-components';
import { useHTTP } from 'hooks/useRequest';
import { isEmptyObject, addressFormatter } from 'helpers/utils';
import useToastStore from 'stores/useToastStore';
import useBasketStore from 'stores/basketStore/useBasketStore';
import { PlusIcon, OutOfStockIcon } from 'components/shared/CommonSvgs';
import Address from './deliveryAddressSearch/Address';
import CheckoutNewAddressModal from './CheckoutNewAddressModal';
import NextStep from './NextStep';
import {
  white,
  orange10,
} from '../../../../assets/stylesheets/v2/abstracts/_variables.scss';

const Delivery = ({
  allowedToDeliverAnywhere,
  accountInvoiceAddress,

  addressBook,
  currentAccountId,
}) => {
  const {
    selectedDeliveryAddress,
    setDeliveryAddress,
    setDeliveryOption,
    setCurrentStep,
    setAccountId,
    setCurrentBasket,
    addToAddressBook,
    deliveryAddressIsInvoiceAddress,
    setDeliveryAddressIsInvoiceAddress,
  } = useBasketStore(state => ({
    setCurrentBasket: state.setCurrentBasket,
    selectedDeliveryAddress: state.selectedDeliveryAddress,
    setDeliveryAddress: state.setDeliveryAddress,
    setDeliveryOption: state.setDeliveryOption,
    setCurrentStep: state.setCurrentStep,
    currentStep: state.currentStep,
    setAccountId: state.setAccountId,
    addToAddressBook: state.addToAddressBook,
    deliveryAddressIsInvoiceAddress: state.deliveryAddressIsInvoiceAddress,
    setDeliveryAddressIsInvoiceAddress:
      state.setDeliveryAddressIsInvoiceAddress,
  }));
  const { addToast } = useToastStore();

  const [showNewAddressModal, setShowNewAddressModal] = useState(false);
  const [requestConfig, setRequestConfig] = useState({
    url: null,
    method: null,
  });
  const [deleteAddressError, setDeleteAddressError] = useState('');
  const nextStepData = {
    address: {
      ...selectedDeliveryAddress,
      type: deliveryAddressIsInvoiceAddress ? 'invoice_address' : '',
    },
    basket: {
      additional_instructions: null,
      purchase_order: null,
      delivery_zone_id: null,
      step: 'additional_information',
    },
  };

  useEffect(() => {
    setDeliveryAddressIsInvoiceAddress(true);
    setCurrentStep('DELIVERY_ADDRESS');
    setAccountId(currentAccountId);
    setDeliveryOption(null);
    setDeleteAddressError('');
  }, []);

  useEffect(() => {
    if (
      isEmptyObject(selectedDeliveryAddress) ||
      deliveryAddressIsInvoiceAddress
    ) {
      setDeliveryAddress(accountInvoiceAddress);
      setDeliveryAddressIsInvoiceAddress(true);
    }
  }, [selectedDeliveryAddress, deliveryAddressIsInvoiceAddress]);

  const { data, error } = useHTTP(requestConfig);

  const setNewAddress = () => {
    setDeliveryAddressIsInvoiceAddress(false);
    setDeleteAddressError('');
    const { id, ...addressWithoutId } = selectedDeliveryAddress;
    setRequestConfig({
      url: '/account/account_addresses',
      method: 'post',
      data: { account_address: addressWithoutId },
    });
  };

  const setInvoiceAsDelivery = () => {
    setDeliveryAddress(accountInvoiceAddress);
    setDeliveryAddressIsInvoiceAddress(true);
  };

  useEffect(() => {
    if (data?.message === 'Address created successfully.') {
      setDeleteAddressError('');
      const { address_id: addressId } = data;
      const newAddress = { ...selectedDeliveryAddress, id: addressId };
      const dataToSend = {
        address: {
          ...newAddress,
          type: deliveryAddressIsInvoiceAddress ? 'invoice_address' : '',
        },
        basket: {
          additional_instructions: null,
          purchase_order: null,
          delivery_zone_id: null,
          step: 'additional_information',
        },
      };

      addToAddressBook(newAddress);
      setDeliveryAddress(newAddress);
      // check why this is 404ing
      setRequestConfig({
        url: '/checkout',
        method: 'put',
        data: dataToSend,
      });
    } else if (data?.message === 'Basket updated successfully.') {
      const { basket } = data;
      setDeleteAddressError('');
      setShowNewAddressModal(false);
      setCurrentBasket(basket);
      setCurrentStep(basket.step.toUpperCase());
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      const { message } = error;
      addToast(message, null, 'decline');
    }
  }, [error]);

  return (
    <div className="row checkout__panel-content">
      {!allowedToDeliverAnywhere && (
        <div className=" alert alert-danger checkout-message col-12">
          <p>
            <strong>
              Your basket contains items that restrict your delivery to only
              your invoice address. If you require further assistance, please do
              not hesitate to contact us.
            </strong>
          </p>
        </div>
      )}

      <h3 className="mt-0 col-12">Invoice Address</h3>

      <div
        className={`col-12 col-lg-6 pap-grid-col pap-grid-col-animated
    checkout__address br-8 b-full b-colour--grey plr-16 
    ptb-16 fl fl-col m-fl-row ai-c jc-sb fs-16 ta-l ff--r`}>
        <div className="form-group width-100">
          <div className="checkout__address-radio">
            <label
              className="form-control with-height"
              htmlFor="address-book-radio-1">
              <input
                id="address-book-radio-1"
                name="radio"
                type="radio"
                onChange={() => setInvoiceAsDelivery()}
                checked={deliveryAddressIsInvoiceAddress}
              />
              <div className="fl fl-col m-fl-row jc-sb">
                <span className="checkout__address-with-name fl m-0 ai-c fs-16">
                  {addressFormatter(accountInvoiceAddress)}
                </span>
              </div>
            </label>
          </div>
        </div>
      </div>

      {allowedToDeliverAnywhere && (
        <>
          <div className="checkout__account-utils col-12">
            <h3 className="mt-0">New Address</h3>
            <Button
              className="btn-black-blue br-4 fl ai-c jc-c fs-14 p-14"
              handleClick={() => setShowNewAddressModal(true)}
              label="aria-label"
              type="button">
              <PlusIcon fillColor={white} className="mr-5" />
              Add New Address
            </Button>

            <CheckoutNewAddressModal
              setDeliveryAddressIsInvoiceAddress={
                setDeliveryAddressIsInvoiceAddress
              }
              setNewAddress={newAddress => setNewAddress(newAddress)}
              modalIsOpen={showNewAddressModal}
              closeModal={() => setShowNewAddressModal(false)}
            />
          </div>

          <div className="col-12 col-md-6 col-lg-8 mb-10">
            <h3 className="mt-0"> Address Book</h3>
            {deleteAddressError && (
              <div className="fl ai-c p-6 fs-14 b-full b-colour--orange bgc---orange br-2 mb-16">
                <OutOfStockIcon
                  className="mr-4"
                  width="20"
                  height="20"
                  fillColor={orange10}
                />
                <p>{deleteAddressError}</p>
              </div>
            )}
            <div className="row pap-grid-row checkout__address__scrollable-results">
              {addressBook?.map(address => (
                <Address
                  key={address.id}
                  setDeliveryAddressIsInvoiceAddress={
                    setDeliveryAddressIsInvoiceAddress
                  }
                  address={address}
                  showGlobalError={message => setDeleteAddressError(message)}
                />
              ))}
            </div>
          </div>
        </>
      )}
      <NextStep
        nextStepData={nextStepData}
        continueCondition={!!selectedDeliveryAddress}
      />
    </div>
  );
};

export default Delivery;
