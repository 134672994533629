import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '@cityelectricalfactors/ui-components';
import { useCookie } from 'hooks/useCookies';
import useDropdown from 'hooks/useDropdown';
import useBasketStore from 'stores/basketStore/useBasketStore';
import { BasketIcon, BasketErrorIcon } from 'components/shared/CommonSvgs';
import Dropdown from 'components/shared/Dropdown';
import BasketPreviewProduct from './BasketPreviewProduct';
import { orange10 } from '../../../assets/stylesheets/v2/abstracts/_variables.scss';

const BasketPreview = ({
  onBasketPage,
  signedIn,
  basketPath,
  checkoutPath,
  whiteGoodsMinOrder,
  currentBasket,
}) => {
  const { basket, setCurrentBasket, basketUpdateMessage } = useBasketStore(
    state => ({
      basket: state.basket,
      setCurrentBasket: state.setCurrentBasket,
      basketUpdateMessage: state.basketUpdateMessage,
      addCurrentBasketLines: state.addCurrentBasketLines,
    }),
  );
  const [pricingCookieValue] = useCookie('show_pricing');
  const parsedCookieValue = JSON.parse(pricingCookieValue);
  const {
    white_goods_total: whiteGoodsTotal,
    lines,
    goods_total: goodsTotal,
  } = basket;
  useEffect(() => {
    if (currentBasket && !onBasketPage) {
      setCurrentBasket(currentBasket);
    }
  }, []);

  const pillIndex = 2;
  const { handleClick } = useDropdown(pillIndex);

  const DropdownAction = () => (
    <DropdownActionButton
      type="button"
      className="header-dropdown-button plr-10 m-plr-20 ptb-12 fl ai-c
        jc-c br-100 btn-header-grey box-shadow-on-hover fw-400 td-none"
      label="Basket Preview"
      handleClick={onBasketPage ? () => window.location.reload() : handleClick}
      aria-haspopup="true">
      {lines?.length ? (
        <span className="header-dropdown-button__indicator">
          {lines.length}
        </span>
      ) : (
        ''
      )}

      <BasketIcon
        width="24"
        height="24"
        viewBox="0 0 24 24"
        className="header-dropdown-button__icon--mobile"
      />

      <div className="site-banner__account-info d-none m-fl fl-col ml-16">
        <span className={signedIn ? 'fs-14' : 'fs-16'}>Basket</span>
        {parsedCookieValue && (
          <span className="fs-16 ellipsis ellipsis--small">
            &pound;{goodsTotal || '0.00'}
          </span>
        )}
      </div>
    </DropdownActionButton>
  );

  return (
    <Dropdown
      className="basket-preview"
      pillIndex={pillIndex}
      dropdownAction={DropdownAction}
      onBasketPage={onBasketPage}
      signedIn={signedIn}
      count={lines?.length}>
      <div className="header-dropdown-button-dropdown__title plr-16">
        <h3 className="fs-20 fw-700 ta-l m-0">
          Basket{' '}
          <span className="fs-16 fw-400 ta-l">
            {`(${lines?.length} product${lines?.length === 1 ? '' : 's'})`}
          </span>
        </h3>
      </div>
      <div
        className="header-dropdown-button-dropdown__products
      header-dropdown-button-dropdown__products--with-border">
        {basketUpdateMessage && (
          <div className="col-12 mb-16">{basketUpdateMessage}</div>
        )}
        {lines?.map(line => (
          <BasketPreviewProduct
            onBasketPage={onBasketPage}
            key={line.line_id}
            pricingCookieValue={parsedCookieValue}
            {...line}
            signedIn={signedIn}
          />
        ))}
      </div>
      {parsedCookieValue && signedIn && (
        <div className="basket-preview__goods-total fl ai-c jc-sb plr-16">
          <h3 className="fs-20 fw-700 ta-l m-0 ptb-20">Goods total</h3>
          <span>&pound;{goodsTotal}</span>
        </div>
      )}
      <div className="basket-preview__actions fl fl-col">
        {+whiteGoodsTotal > 0 && +whiteGoodsTotal < whiteGoodsMinOrder && (
          <div
            className={`product-stock b-full b-colour--orange
            bgc--orange br-2 fl jc-fs p-l24 plr-16 pt-10 pb-10 mt-16 mb-16`}>
            <BasketErrorIcon
              width="20"
              height="20"
              viewBox="0 0 20 20"
              className="o-vis"
              fillColor={orange10}
            />
            <p className="fl ai-c m-0 fs-12 ml-8">
              <strong>
                You have spent &pound;{whiteGoodsTotal} on white goods items,
                which require premium delivery. You need to spend &pound;
                {whiteGoodsMinOrder} or more on these items to checkout.
              </strong>
            </p>
          </div>
        )}
        <a
          href={basketPath}
          className="btn btn-orange--bordered fl ai-c jc-c mb-16 fw-700">
          View Basket
        </a>
        <a
          href={checkoutPath}
          className={`btn btn-orange fl ai-c jc-c fw-700
          ${+whiteGoodsTotal && +whiteGoodsTotal < whiteGoodsMinOrder ? 'disabled' : ''}`}>
          Checkout
        </a>
      </div>
    </Dropdown>
  );
};

const DropdownActionButton = styled(Button)`
  min-height: 32px;
  min-width: 32px;
`;

export default BasketPreview;
