/* eslint-disable dot-notation */
/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import { parse } from 'uri-js';
import Pagination from 'react-js-pagination';
import styled from 'styled-components';
import useSearchResults from 'hooks/useSearchResults';
import usePricing from 'hooks/usePricing';
import usePricingStore from 'stores/pricingStore/usePricingStore';
import useUserCompareStore from 'stores/userCompareStore/useUserCompareStore';
import ProductCompare from 'components/ProductCompare';
import AddProductToBasket from 'components/AddProductToBasket/';
import ProductsLoading from 'components/ProductListings/components/products_loading';
import ProductListings from 'components/ProductListings/components/product_listings';
import ProductSorting from 'components/ProductListings/components/product_sorting';
import ProductAggregations from 'components/ProductListings/components/aggregates/aggregations';
import ProductsSearchTitle from 'components/ProductListings/components/products_search_title';
import ProductView from 'components/ProductListings/components/product_view';
import toRem from 'utils/toRem';

import {
  grey70,
  grey5,
  white,
} from '../../../assets/stylesheets/v2/abstracts/_variables.scss';
import { FilterIcon } from '../shared/CommonSvgs';

const ProductListingsComponent = ({
  searchTerm: searchQuery,
  mode,
  searchUrl,
  filterText,
  fullPageSort,
  collapseAllFilters,
  showCheckbox,
  clearAllText,
  useArrowIcons,
  data,
  isProductList,
  showVat,
  categoryId,
  brand_id: brandId,
  title,
  basketId,
  signedIn,
  currentCompareProducts,
  comparePageUrl,
  mainPerPage,
}) => {
  const { response, setConfig: setSearchResultsConfig } = useSearchResults();
  const { response: pricingResponse, setConfig: setPricingConfig } =
    usePricing();
  const { setProductListPrices, setProductPricingError } = usePricingStore(
    state => ({
      setProductListPrices: state.setProductListPrices,
      setProductPricingError: state.setProductPricingError,
    }),
  );
  const sortOptions = [
    { display: 'Relevance', value: '' },
    { display: 'Product - A-Z', value: 'description.sort_asc' },
    { display: 'Product - Z-A', value: 'description.sort_desc' },
    { display: 'Part Code - A-Z', value: 'catalogue.sort_asc' },
    { display: 'Part Code - Z-A', value: 'catalogue.sort_desc' },
    { display: 'Brand - A-Z', value: 'brand.sort_asc' },
    { display: 'Brand - Z-A', value: 'brand.sort_desc' },
  ];

  const config = {
    searchUrl: `/search.json`,
    showSidebar: true,
    showHeader: true,
    showContent: true,
    showPagination: true,
    searchQuery,
    sidebarType: 'filters || categories',
    flexDirection: 'row',
    contentFlexDirection: 'row',
    mode,
    sorting_options: sortOptions,
    showAddToFavourites: false,
    showCompare: false,
    showPricing: true,
    showVat: true,
    showAddToBasket: {
      onProductList: true,
      onQuickView: false,
      basketId,
      component: AddProductToBasket,
      buttonText: 'Add to Basket',
    },
    compareAmount: 5,
    showBrandImage: true,
    showCatalogue: true,
    showStockCode: true,
    collapseAllFilters: true,
    showDescriptionBrand: true,
    isProductList: true,
    signedIn,
    hideQuantityButtons: true,
  };

  const [activePage, setActivePage] = useState(1);
  const [aggregations, setAggregations] = useState([]);
  const [appliedAggregates, setAppliedAggregates] = useState({});
  const [loading, setLoading] = useState(false);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [statePage, setStatePage] = useState(1);
  const [statePerPage, setStatePerPage] = useState(mainPerPage || 20);
  const [perPageOptions] = useState([12, 24, 48]);
  const [stateSort, setStateSort] = useState('');
  const [stateSortDisplay, setStateSortDisplay] = useState('');
  const [products, setProducts] = useState([]);
  const [productsTotal, setProductsTotal] = useState(0);
  const [sortingOptions, setSortingOptions] = useState([]);
  const [toggleOpen, setToggleOpen] = useState(false);
  const [urlParameters, setUrlParameters] = useState();

  const [productViewType, setProductViewType] = useState(
    localStorage.getItem('productViewType') || 'grid',
  );

  const { addCurrentCompareProducts } = useUserCompareStore(state => ({
    addCurrentCompareProducts: state.addCurrentCompareProducts,
  }));

  const setViewType = view => {
    setProductViewType(view);
    localStorage.setItem('productViewType', view);
  };

  const validPageNumber = () =>
    !(
      (statePage > Math.ceil(productsTotal / statePerPage) ||
        statePage <= 0 ||
        isNaN(statePage)) &&
      productsTotal > 0
    );

  const setPage = count => {
    setStatePage(count);
    setActivePage(count);

    window.scrollTo(0, 0);
  };

  // search for products
  useEffect(() => {
    if (!response.result) return;
    if (response.result) {
      const { success } = response.result;

      const {
        data: { products: newProducts },
      } = response.result;

      if (success && newProducts !== products && !!newProducts.length) {
        const {
          data: {
            total,
            aggregations: newAggregations,
            sort_options: newSortOptions,
          },
        } = response.result;
        setProductsTotal(total);
        setProducts(newProducts);
        setAggregations(newAggregations);
        setSortingOptions(
          newSortOptions.length > 0
            ? newSortOptions
            : [
                { display: 'Relevance', value: '' },
                {
                  display: 'Product - A-Z',
                  value: 'description.sort_asc',
                },
                {
                  display: 'Product - Z-A',
                  value: 'description.sort_desc',
                },
              ],
        );
        setLoading(false);

        const productsForPricing = {
          products: newProducts.map(p => p.id).join(','),
        };

        const sortedOption = sortOptions.filter(
          option => option.value === stateSort,
        );

        setStateSortDisplay(sortedOption[0].display);

        setPricingConfig({
          method: 'GET',
          url: `/prices`,
          isProductList,
          params: productsForPricing,
        });

        // end get all prices

        if (validPageNumber() === false) {
          setPage(1);
        }
      }
      setLoading(false);
    }
  }, [response.result]);

  useEffect(() => {
    if (!pricingResponse.result) return;
    if (pricingResponse.result) {
      const { success, data: pricingData } = pricingResponse.result;
      if (success) {
        setProductListPrices(pricingData.prices);
      } else {
        const {
          data: { error: errorMessage, error_title: errorTitle },
        } = pricingResponse.result;

        const pricingError = { errorMessage, errorTitle };
        setProductPricingError(pricingError);
      }
    }
  }, [pricingResponse.result]);

  const toggleFilters = () => {
    const filters = document.querySelector('.product-filters');
    filters.classList.toggle('product-filters--open');

    filters.scrollIntoView();
    setTimeout(() => {
      document.body.classList.toggle('no-scroll');
    }, 100);

    // Close Sort options
    if (document.querySelector('.sort-options')) {
      document.querySelector('.products-options__sort').click();
    }
  };

  const formatQuery = () => {
    const q = mode === 'search' ? searchQuery : '*';

    const query = {
      q,
      per_page: statePerPage,
      page: statePage,
    };

    if (stateSort !== '') {
      query['sort'] = stateSort;
    }

    if (mode === 'category') {
      query['category'] = categoryId;
      query['force_category'] = true;
    }

    if (mode === 'brand' && brandId) {
      query['brand'] = brandId;
      query['force_brand'] = true;
    }

    Object.keys(appliedAggregates).forEach(key => {
      query[key] = appliedAggregates[key];
    });

    return query;
  };

  const searchProducts = () => {
    setLoading(true);
    const query = formatQuery();
    setSearchResultsConfig({
      method: 'GET',
      url: searchUrl,
      params: query,
    });
  };

  // cont freshSearch
  useEffect(() => {
    searchProducts();

    return () => {};
  }, [statePage]);

  const freshSearch = (firstPage = true) => {
    const page = firstPage ? 1 : statePage;

    setStatePage(page);
    searchProducts();
  };

  // const toggle aggregate
  useEffect(() => {
    freshSearch(false);
  }, [appliedAggregates, stateSort, statePerPage]);

  const setSort = value => {
    setStateSort(value);
  };

  const createObjFromURI = () => {
    const uri = decodeURI(location.search.substr(1));
    const chunks = uri.split('&');
    const params = {};

    for (let i = 0; i < chunks.length; i++) {
      const chunk = chunks[i].split('=');
      if (chunk[0].search('\\[\\]') !== -1) {
        if (typeof params[chunk[0]] === 'undefined') {
          params[chunk[0]] = [chunk[1]];
        } else {
          params[chunk[0]].push(chunk[1]);
        }
      } else {
        params[chunk[0]] = chunk[1];
      }
    }

    return params;
  };

  const parseURLPageNumber = newUrlParameters =>
    newUrlParameters['page'] !== undefined
      ? parseInt(newUrlParameters['page'])
      : 1;

  const parseURLSorting = newUrlParameters =>
    newUrlParameters['sort'] !== undefined ? newUrlParameters['sort'] : '';

  const defaultPerPageValue = () => mainPerPage || 20;

  const parseURLPerPage = newUrlParameters => {
    const perPage =
      newUrlParameters['per_page'] !== undefined
        ? parseInt(newUrlParameters['per_page'])
        : statePerPage;
    return perPageOptions.includes(perPage) ? perPage : defaultPerPageValue();
  };

  const parseURL = () => {
    const urlParams = parse(window.location.search);

    setUrlParameters(urlParams);

    const uriObj = createObjFromURI(urlParams.query);

    setActivePage(parseURLPageNumber(uriObj));
    setStatePage(parseURLPageNumber(uriObj));
    setStatePerPage(parseURLPerPage(uriObj));
    setStateSort(parseURLSorting(uriObj));
    setToggleOpen(false);
  };

  const updateURLParam = (value, paramName, performSearch = true) => {
    let currentParams =
      window.location.search !== ''
        ? window.location.search
        : window.location.pathname;
    const param = `${paramName}=${value}`;
    const paramRegex = new RegExp(`[?&]${paramName}=([^&#]*)`);

    if (window.location.search === '') {
      currentParams += `?${param}`;
    } else {
      currentParams =
        paramRegex.test(window.location.search) === true
          ? currentParams.replace(paramRegex, `&${param}`)
          : `${currentParams}&${param}`;
    }

    if (currentParams[0] === '&') {
      currentParams = currentParams.replace('&', '?');
    }

    history.pushState(null, null, currentParams);
    if (performSearch) {
      parseURL();
    }
  };

  // const set sort
  useEffect(() => {
    updateURLParam(stateSort, 'sort');
  }, [stateSort]);

  const parseURLAggregations = () => {
    if (window.location.search.length > 0) {
      const currentUrl = createObjFromURI(window.location.search);
      const disallowedKeys = [
        'activePage',
        'page',
        'per_page',
        'q',
        'sort',
        'button',
        'utf8',
      ];
      const newAppliedAggregates = {};

      Object.keys(currentUrl).forEach(key => {
        if (disallowedKeys.includes(key)) return;
        const keyName = key.replace('[]', '');

        // This is the workout around for decoding the URL Params
        const currentAgg = currentUrl[key];
        for (let i = 0; i < currentAgg.length; i++) {
          currentAgg[i] = decodeURIComponent(currentAgg[i]);
        }
        newAppliedAggregates[keyName] = currentAgg;
      });

      setAppliedAggregates(newAppliedAggregates);
    }
  };

  // const parse url
  useEffect(() => {
    parseURLAggregations(urlParameters);
  }, [urlParameters]);

  // equiv - componentDidMount
  useEffect(() => {
    parseURL();
    window.addEventListener('popstate', () => parseURL());

    addCurrentCompareProducts(currentCompareProducts);
  }, []);

  const resetAllAggregates = () => {
    let currentParams = window.location.search;
    const path = window.location.pathname;

    Object.keys(aggregations).forEach(agg => {
      const paramArrayRegex = new RegExp(`[?&](${agg}[[]]=[^&#]*)`, 'g');
      const paramRegex = new RegExp(`[?&](${agg}=[^&#]*)`, 'g');
      currentParams = currentParams.replace(paramArrayRegex, '');
      currentParams = currentParams.replace(paramRegex, '');
    });

    if (currentParams.length === 0) {
      history.pushState(null, null, path);
    } else {
      if (currentParams[0] === '&') {
        currentParams = currentParams.replace('&', '?');
      }
      history.pushState(null, null, path + currentParams);
    }

    setAppliedAggregates({});
    setToggleOpen(false);
  };

  const addAggregateToURL = (current, aggValue, formattedKey, updateUrl) => {
    if (updateUrl) {
      let currentParams = `${
        window.location.search
      }&${formattedKey}[]=${encodeURIComponent(aggValue)}`;
      if (currentParams[0] === '&') {
        currentParams = currentParams.replace('&', '?');
      }
      history.pushState(null, null, currentParams);
    }
    current[formattedKey] = [...current[formattedKey], aggValue];
  };

  const removeAggregateFromURL = (
    current,
    aggValue,
    formattedKey,
    updateUrl,
  ) => {
    if (updateUrl) {
      const paramRegex = new RegExp(
        `[?&]${formattedKey}[[]]=${encodeURIComponent(aggValue).replace(
          /(?=[() ])/g,
          '\\',
        )}(?=$|&)`,
        'g',
      );
      let currentParams = window.location.search.replace(paramRegex, '');

      if (currentParams[0] === '&') {
        currentParams = currentParams.replace('&', '?');
      }
      currentParams =
        currentParams === '' ? window.location.pathname : currentParams;
      history.pushState(null, null, currentParams);
    }
    current[formattedKey] = current[formattedKey].filter(
      item => item !== aggValue,
    );
  };

  const toggleAggregate = (
    aggType,
    aggValue,
    performSearch = true,
    updateUrl = false,
  ) => {
    aggValue = decodeURIComponent(aggValue);
    const formattedKey = aggType.replace(/ /g, '_');
    const current = appliedAggregates;

    if (appliedAggregates[formattedKey] === undefined) {
      const applied = appliedAggregates;
      applied[formattedKey] = [aggValue];

      if (updateUrl) {
        addAggregateToURL(current, aggValue, formattedKey, true);
      }

      if (applied) {
        Object.keys(applied).forEach(key => {
          applied[key] = applied[key].filter(
            (item, pos) => applied[key].indexOf(item) === pos,
          );
        });
      }

      setAppliedAggregates(applied);
      if (performSearch) {
        freshSearch(false);
      }
    } else {
      // TODO: Dissabling till i can look into this
      // eslint-disable-next-line no-unused-expressions
      current[formattedKey].includes(aggValue) === true
        ? removeAggregateFromURL(current, aggValue, formattedKey, updateUrl)
        : addAggregateToURL(current, aggValue, formattedKey, updateUrl);

      // Remove any empty values
      Object.keys(current).forEach(agg => {
        if (current[agg].length === 0) {
          delete current[agg];
        }
      });

      setAppliedAggregates(current);
      if (performSearch) {
        freshSearch(false);
      }
    }
  };

  const addToFavourites = productId => {
    return productId;
  };

  const calculatePagination = () => {
    setNumberOfPages(Math.ceil(productsTotal / statePerPage));
  };

  // const search products
  useEffect(() => {
    if (productsTotal > 0) {
      calculatePagination();
    }
  }, [productsTotal]);

  const checkToggle = toggle => {
    setToggleOpen(toggle);
    return toggleOpen;
  };

  return (
    <>
      {searchQuery ? (
        <ProductsSearchTitle
          mode={mode}
          title={title}
          q={searchQuery}
          productsTotal={productsTotal}
        />
      ) : (
        ''
      )}
      <div className="container row">
        <ProductsLoading loading={loading} />
        {productsTotal !== 0 && (
          <>
            <ProductListingsMobileButtons className="product-buttons col-12 mt-34 mb-26">
              <button
                className="filter-toggle__button b-full br-4 b-colour--grey fl 
                jc-sb ai-c fs-18 fw-700  bg-none"
                type="button"
                onClick={toggleFilters}>
                {filterText || 'Filters'} <FilterIcon />
              </button>
              <ProductSorting
                mobileSort
                sortDisplay={stateSortDisplay}
                fullPageSort={fullPageSort}
                checkToggle={checkToggle}
                setSort={setSort}
                sortingOptions={sortingOptions}
                toggleOpen={toggleOpen}
              />
            </ProductListingsMobileButtons>
            <ProductFilters className="product-filters col-12 m-col-3">
              <ProductAggregations
                closeMobileAgg={toggleFilters}
                aggregations={aggregations}
                applied_aggregates={appliedAggregates}
                collapseAllFilters={collapseAllFilters}
                toggleAggregate={toggleAggregate}
                resetAllAggregates={resetAllAggregates}
                type={mode}
                showCheckbox={showCheckbox}
                products_total={productsTotal}
                filterText={filterText}
                clearAllText={clearAllText}
                useArrowIcons={useArrowIcons}
              />
            </ProductFilters>
          </>
        )}
        <ProductsList className="products-list col-12 m-col-9">
          {productsTotal !== 0 && (
            <div className="products-options fl jc-fe ai-c mb-10">
              <ProductSorting
                sortDisplay={stateSortDisplay}
                checkToggle={checkToggle}
                setSort={setSort}
                sortingOptions={sortingOptions}
                toggleOpen={toggleOpen}
              />
              <ProductView
                setViewType={setViewType}
                currentViewType={productViewType}
              />
            </div>
          )}

          <ProductListings
            products={products}
            showVat={showVat}
            data={data}
            config={config}
            addToFavourites={addToFavourites}
            products_total={productsTotal}
            loading={loading}
            isProductList={isProductList}
            viewType={productViewType}
          />
          {numberOfPages > 1 && (
            <Pagination
              activeClass="products-pagination__link--selected"
              activePage={activePage}
              nextPageText=">"
              prevPageText="<"
              firstPageText="First"
              hideDisabled
              innerClass="no-bullet products-pagination"
              itemClass="products-pagination__link"
              itemsCountPerPage={statePerPage}
              lastPageText="Last"
              linkClass="product-pagination__link-toggle td-none"
              linkClassNext="product-pagination__link-toggle--next"
              linkClassPrev="product-pagination__link-toggle--prev"
              onChange={setPage}
              pageRangeDisplayed={4}
              totalItemsCount={productsTotal}
            />
          )}
          <ProductCompare comparePageUrl={comparePageUrl} />
        </ProductsList>
      </div>
    </>
  );
};

const ProductListingsMobileButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.125rem;

  > button {
    flex: 1;
    margin-right: 1rem;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0 0.5rem 0 1rem;
  }

  .products-options__sort {
    height: ${toRem(50)};
  }

  .sort-options {
    left: auto;
    right: 0;
    top: 20px;
  }

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

const ProductFilters = styled.div`
  display: none;

  &.product-filters--open {
    padding: ${toRem(16)};
    background-color: ${white};
    display: block;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    overflow-y: scroll;
    right: 0;
    width: 100vw;
    z-index: 10;
    box-sizing: border-box;
  }

  @media only screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
    margin-right: ${toRem(10)};
  }
`;

const ProductsList = styled.div`
  .products-pagination {
    display: flex;
    margin: 2rem 0;
    padding: 0;
  }

  .products-pagination__link {
    align-items: center;
    color: ${grey70};
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    border: 1px solid transparent;

    a {
      color: ${grey70};
      display: block;
    }

    &:hover {
      background-color: ${grey5};
      border-radius: 4px;
      cursor: pointer;

      a {
        color: ${grey70};
      }
    }

    &.products-pagination__link--selected {
      border: 1px solid ${grey70};
      border-radius: 4px;
      color: ${grey70};

      a {
        color: ${grey70};
      }

      &:hover {
        background-color: ${white};

        a {
          color: ${grey70};
        }
      }
    }
  }
`;

export default ProductListingsComponent;
