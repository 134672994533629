import React from 'react';
import {
  BasketWarningIcon,
  BasketErrorIcon,
} from 'components/shared/CommonSvgs';

const BasketStockWarning = ({ quantity, stock }) => {
  const isOutOfStock = stock <= 0;
  const isInsufficientStock = quantity > stock && stock > 0;
  return (
    <div
      className={`basket-stock-warning fl ai-c jc-fe p-l24 pr-24 pt-10 pb-10
     ${isOutOfStock && 'basket-stock-warning__no-stock'}`}>
      {isOutOfStock && (
        <>
          <BasketErrorIcon className="mr-8" />
          <p className="fl ai-c m-0 fs-12 ">
            This item is out of stock and will be placed on backorder. The item
            will be despatched once available.
          </p>
        </>
      )}
      {isInsufficientStock && (
        <>
          <BasketWarningIcon className="mr-8" />
          <p className="fl ai-c m-0 fs-12 ">
            Insufficient stock, there are currently {stock} in stock, the
            remainder will be despatched when available.
          </p>
        </>
      )}
    </div>
  );
};

export default BasketStockWarning;
