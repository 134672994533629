import React, { useEffect } from 'react';
import useUserFavouritesStore from 'stores/userFavouritesStore/useUserFavouritesStore';
import { FavouriteIcon } from 'components/shared/CommonSvgs';

const UserFavourites = ({ currentFavouriteProducts, userFavouritesPath }) => {
  const { userFavouritesProducts, addCurrentFavouriteProducts } =
    useUserFavouritesStore();

  useEffect(() => {
    if (currentFavouriteProducts) {
      addCurrentFavouriteProducts(currentFavouriteProducts);
    }
  }, [currentFavouriteProducts, addCurrentFavouriteProducts]);

  return (
    <div className="header-dropdown-button-wrapper user-favourites">
      <a
        href={userFavouritesPath}
        className="header-dropdown-button plr-10 m-plr-20 ptb-12 fl ai-c 
        jc-c br-100 btn-header-grey box-shadow-on-hover fw-400 td-none ml-16"
        aria-label="User Favourites">
        {!!userFavouritesProducts.length && (
          <span className="header-dropdown-button__indicator">
            {userFavouritesProducts.length}
          </span>
        )}
        <FavouriteIcon
          className="header-dropdown-button__icon--mobile"
          width="24"
          height="24"
          viewBox="0 0 22 22"
        />
      </a>
    </div>
  );
};

export default UserFavourites;
